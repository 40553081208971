import {
  createBarScalesX,
  createDatalabels,
  createFixedDatalabels,
  createLayoutWithDatalabels,
} from "../helpers/CommonChartConfigs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js";

function formatDate(input) {
  let datePart = input.match(/\d+/g),
    year = datePart[0].substring(2),
    month = datePart[1];

  return month + "/" + year;
}

function GetLabels(data, maxItens) {
  let labels = [];
  data.sort((a, b) => {
    if (new Date(a.date).getTime() > new Date(b.date).getTime()) {
      return 1;
    }
    if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
      return -1;
    }
    return 0;
  });

  data.reverse();

  data.map((item, index) => {
    if (index < maxItens) {
      labels.push(formatDate(item?.date));
    }

    return item;
  });

  return labels.reverse();
}

function GetValues(data, maxItens) {
  let values = [];
  data.sort((a, b) => {
    if (new Date(a.date).getTime() > new Date(b.date).getTime()) {
      return 1;
    }
    if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
      return -1;
    }
    return 0;
  });

  data.reverse();

  data.map((item, index) => {
    if (index < maxItens) {
      values.push(item?.value);
    }
    return item;
  });

  return values.reverse();
}

function GetAverage(data, total) {
  const today = new Date();
  const lastDay = new Date();
  lastDay.setMonth(today.getMonth() - 6);
  let value = 0;
  let sub = 0;

  data.map((item) => {
    let itemDate = new Date(item?.date);
    itemDate.setDate(itemDate.getDate() + 1);

    if (itemDate <= today && itemDate >= lastDay) {
      value += item?.value;
      sub += 1;
    }
    return item;
  });
  /* 
  6 últimos meses disponíveis
  data.every(function (item) {
    let itemDate = new Date(item?.date);
    itemDate.setDate(itemDate.getDate() + 1);

    if (itemDate <= today && itemDate >= lastDay) {
      value += item?.value;
      sub += 1;
    }
    if (sub >= total) {
      return false;
    } else {
      return true;
    }
  }); */

  return (value / sub).toFixed(2);
}

function GetByMonth(data, difMonth) {
  data.sort((a, b) => {
    if (new Date(a.date).getTime() > new Date(b.date).getTime()) {
      return 1;
    }
    if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
      return -1;
    }
    return 0;
  });

  const month = data.length - difMonth - 1;
  return {
    value: data[month]?.value,
    date: formatDate(data[month]?.date),
  };
}

export default function getCapacityHistoricLine(response, maxItens = 12) {
  const iMax = 4;
  const labels = GetLabels(response, maxItens);

  const data = GetValues(response, maxItens);

  const dataAverage = GetAverage(response, 6);
  const lastMonth = GetByMonth(response, 1);
  const currentMonth = GetByMonth(response, 0);
  const chartData = {
    type: "line",
    data: {
      labels,
      datasets: [
        {
          borderColor: "#5C42DB",
          lineTension: 0,
          borderWidth: 3,
          pointRadius: 6,
          pointBorderWidth: 0.5,
          pointBackgroundColor: "#5C42DB",
          pointBorderColor: "#5C42DB",
          fill: false,
          data: data,
          spanGaps: true,
        },
      ],
    },
    plugins: [ChartDataLabels],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      labels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },

      plugins: {
        datalabels:
          maxItens > 6
            ? createFixedDatalabels(
                "#5C42DB",
                "#00000012",
                "white",
                (value, context) => {
                  const valueData = Number(value).toFixed(1);
                  return valueData + "";
                },
                iMax > 32
              )
            : createDatalabels(
                "#5C42DB",
                "#00000012",
                "white",
                (value, context) => {
                  const valueData = Number(value).toFixed(1);
                  return valueData + "";
                },
                iMax > 32
              ),
      },

      onHover: function (event, elements) {
        var chart = this;

        // Store hovered elements under a private property
        // named $_user_ to make sure it doesn't conflict.
        var expando = chart.$_user_ || (chart.$_user_ = {});

        // To prevent updating the chart for every mouse move,
        // let's first check that the hovered items have changed.
        if (!Chart.helpers.arrayEquals(expando.hovered, elements)) {
          expando.hovered = elements;
          chart.update();
        }
      },

      layout: createLayoutWithDatalabels(),

      scales: createBarScalesX(
        "#6A6A6A20",
        "#6A6A6A",
        false,

        10,
        "decimal"
      ),
    },
  };

  return {
    data: {
      chartData,
      type: "line",
    },
    dataAverage: dataAverage,
    lastMonth: lastMonth,
    currentMonth: currentMonth,
  };
}
