import styled from "styled-components";
import fonts from "../../config/fonts";
import FontStyles from "../../genericComponents/styles/fontStyles";
import { Colors, MediaQueries, Spacing } from "../../config";

const Container = styled.div(() => {
  return {
    display: "flex",
    width: "100%",
    height: "100vh",
    flexDirection: "column",
    padding: Spacing(2, 3, 2, 3),
    justifyContent: "space-evenly",

    [MediaQueries.mdDown]: {
      padding: Spacing(2, 0),
    },
    [MediaQueries.xlDown]: {
      padding: Spacing(0, 0),
    },
    [MediaQueries.smDown]: {
      justifyContent: "flex-start",
      padding: Spacing(2, 0),
    },
  };
});

const Content = styled.div(() => {
  return {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    paddingInline: "19%",
    overflowY: "auto",
    textAlign: "left",
    marginBottom: Spacing(8),
    [MediaQueries.smDown]: {
      marginTop: Spacing(11),
    },
  };
});

const Header = styled.div(() => {
  return {
    width: "100%",
    height: "fit-content",
    // margin: Spacing(3),
    paddingInline: "18%",
    display: "flex",
    alignSelf: "center",
    justifyContent: "flex-start",
    [MediaQueries.smDown]: {
      justifyContent: "center",
    },
  };
});

const Title = styled.p(() => {
  return {
    color: Colors.purple,
    opacity: 0.88,
    margin: 0,
    textAlign: "left",
    ...FontStyles.bold42,
    [MediaQueries.smDown]: {
      fontSize: Spacing(4.2),
    },
  };
});

const Subtitle = styled.p(() => {
  return {
    color: Colors.grayText,
    ...FontStyles.medium16,
    opacity: 0.88,
    margin: 0,
    paddingTop: Spacing(2.5),
    paddingBottom: Spacing(5),
    [MediaQueries.smDown]: {
      paddingTop: Spacing(1),
      paddingBottom: Spacing(1.5),
    },
  };
});

const EcomLogo = styled.img(() => {
  return {
    width: Spacing(30),
    height: "auto",
  };
});

const P = styled.p(({ gray, end }) => {
  return {
    textAlign: end ? "right" : "left",
    fontFamily: gray ? fonts.medium : fonts.bold,
    color: gray ? Colors.grayText : Colors.purple,
    fontSize: fonts.sizes.regular,
    [MediaQueries.smDown]: {
      textAlign: "center",
      marginTop: end && 0,
    },
  };
});

const Span = styled.span(() => {
  return {
    fontFamily: fonts.bold,
    color: Colors.purple,
    fontSize: fonts.sizes.regular,
    marginLeft: 3,
    cursor: "pointer",
  };
});

const ImageBG = styled.div(({ $Image }) => {
  return {
    width: "100%",
    height: "100%",
    backgroundImage: $Image,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
});
const ContainerImage = styled.div(() => {
  return {
    [MediaQueries.smDown]: {
      display: "none",
    },
  };
});
const Version = styled.div(() => {
  return {
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center",
  };
});
const Styles = {
  Container,
  Content,
  Title,
  Subtitle,
  Header,
  EcomLogo,
  P,
  Span,
  ImageBG,
  ContainerImage,
  Version
};

export default Styles;
