import React, { Component } from "react";
import { validations } from "../../utils";
import Input from "./Input";

export default class InputEmail extends Component {
  render() {
    const { maxLength = 100, ...props } = this.props;

    return (
      <Input
        {...props}
        maxLength={maxLength}
        type="email"
        validation={validations.isEmailValid}
      />
    );
  }
}
