import styled from "styled-components";
import fonts from "../../../config/fonts";
import { Colors, MediaQueries, Spacing } from "../../../config";

const ImageBG = styled.div(({ $Image }) => {
  return {
    width: "100%",
    height: "100%",
    backgroundImage: $Image,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [MediaQueries.xlDown]: {
      paddingTop: Spacing(5),
    },
  };
});

const CellphoneContainer = styled.img(() => {
  return {
    width: "auto",
    height: "50vh",
  };
});

const Text = styled.p(({ title }) => {
  return {
    fontFamily: fonts.bold,
    fontSize: title ? fonts.sizes.extraLarge : fonts.sizes.large,
    color: Colors.white,
    textAlign: "center",
    margin: 0,
    padding: 0,

    [MediaQueries.mdDown]: {
      fontSize: title ? fonts.sizes.large : fonts.sizes.medium,
    },
  };
});

const DownloadContainer = styled.img(() => {
  return {
    width: Spacing(23),
    height: "auto",
    margin: Spacing(1),
    cursor: "pointer",

    [MediaQueries.mdDown]: {
      width: Spacing(20),
    },
  };
});

const Styles = {
  ImageBG,
  CellphoneContainer,
  Text,
  DownloadContainer,
};

export default Styles;
