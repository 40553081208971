import styled from "styled-components";
import { MediaQueries, Spacing } from "../../config";

const EcomLogo = styled.img(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: Spacing(25),
    height: "auto",
    [MediaQueries.smDown]: {
      width: Spacing(22),
    },
  };
});

const Line = styled.div(({ theme }) => {
  // const { spacing, palette: colors } = theme;
  return {
    display: "block",
    border: 0,
    borderBottom: "1px solid #EFEFEF",
    paddingTop: Spacing(3)
  };
});

const Styles = {
  EcomLogo,
  Line,
};

export default Styles;
