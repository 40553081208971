import React from "react";
import { FormHelperText, Grid } from "@material-ui/core";
import ptBr from "../../../config/texts/pt-br";
import { useNavigate } from "react-router-dom";
import {
  ButtonContained,
  ButtonText,
  CheckBox,
  InputCNPJandCPF,
  InputEmail,
  InputPassword,
} from "../../../genericComponents";
import Styles from "../NewAccountStyles";
import { FormFull } from "form-full";
import { masks, validations, hooks, SessionStorage } from "../../../utils";
import { api } from "../../../services";
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function StepNewAccount({ RenderSwiperDots, onSubmit, loading }) {
  const texts = ptBr.newAccount.createNewAccount;

  const navigate = useNavigate();

  const [buttonDisabled, setButton] = React.useState(true);
  const [ref, setRef] = React.useState();
  const [checkError, setCheckError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const { call } = hooks.useRequest();
  const toastOps = (message) => {
    toast.warning(message, {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const sendTokenData = async (r) => {
    const data = await r.testErrorsAndReturnData();
    if (!data?.hasError) {
      call(null, api.pegaToken(), (resposta) => {
        if(resposta.ok) {
          console.log("LARAVEL TOKEN: " + resposta?.data?.token_type + " " + resposta?.data?.access_token);
          SessionStorage.setItem(
            "middleware_token",
            resposta?.data?.token_type + " " + resposta?.data?.access_token
          );
          call(null, api.verificaStatusParceiro(data?.data?.cpfCnpj, data?.data?.email), (response) => {
            if(response.ok) {
                  if(response.data === 'INATIVO') {
                    setcheckInativo(true);
                    console.log(response.data);
                    toastOps('O Parceiro com o CNPJ '+ data?.data?.cpfCnpj + ' está inativo ou com o email inválido, entre em contato com o administrador do sistema.');      
                  } else {
                    setcheckInativo(false);
                    console.log('PODE CRIAR');
                    if (check) {
                      if (data?.data?.password === data?.data?.confirmPassword) {
                        onSubmit(data?.data);
                      } else {
                        setPasswordError(true);
                      }
                    } else {
                      setCheckError(true);
                    }
                  }
              }
          });
        }
      });
    } else {
      toastOps('Corrija os erros para continuar o cadastro!');      
    }
    
    
  };

  const [check, setCheck] = React.useState(false);
  const [checkInativo, setcheckInativo] = React.useState(false);

  return (
    <FormFull formRef={setRef} onSubmit={() => {}}>
      <Styles.Content className="corponovoregistroscrool">
        <Styles.Title>{texts.title}</Styles.Title>
        <Styles.Subtitle>{texts.subTitle}</Styles.Subtitle>
        <InputCNPJandCPF
          name="cpfCnpj"
          label={texts.user}
          required={texts.userMessage}
          validation={validations.validateCNPJandCPF}
          maskToSubmit={masks.cpfCnpjToRequest}
        />
        <InputEmail
          name="email"
          label={texts.email}
          required={texts.userMessage}
          validation={validations.isEmailValid}
        />
        <Styles.InputName
          name="name"
          label={texts.name}
          required={texts.userMessage}
        />
        <InputPassword
          name="password"
          label={texts.password}
          required={texts.passwordMessage}
          validation={validations.isPassword}
        />
        <InputPassword
          name="confirmPassword"
          label={texts.confirmPassword}
          required={texts.passwordMessage}
          validation={validations.isPassword}
          onChange={() => {
            setButton(false);
          }}
        />
        <FormHelperText error={passwordError}>
          {Boolean(passwordError) ? "As senhas não correspondem." : " "}
        </FormHelperText>

        <CheckBox
          actualValue={check}
          name="terms"
          className="checknovo"
          register
          click={(value) => setCheck(value)}
        />
        <FormHelperText error={checkError}>
          {Boolean(checkError) ? "Campo obrigatório." : " "}
        </FormHelperText>
        <FormHelperText error={checkInativo}>
          {Boolean(checkInativo) ? "Parceiro está inativo." : " "}
        </FormHelperText>

        <ButtonContained
          name="continue"
          loading={loading}
          disabled={buttonDisabled}
          feedback={true}
          onClick={() => sendTokenData(ref)}
          label={texts.send}
          className="botaocriar"
        />
      </Styles.Content>

      <Styles.Footer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {RenderSwiperDots(texts.render)}
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={7}
            style={{ display: "flex", justifyContent: "space-between" }}>
            <ButtonText
              name="back"
              loading={loading}
              disabled
              fullWidth={false}>
              <Styles.ArrowCircle>
                <Styles.ArrowLeft />
              </Styles.ArrowCircle>
              Voltar
            </ButtonText>
            <ButtonText
              name="next"
              action="submit"
              loading={loading}
              disabled={buttonDisabled}
              fullWidth={false}>
              Avançar
              <Styles.ArrowCircle>
                <Styles.ArrowRight />
              </Styles.ArrowCircle>
            </ButtonText>
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                onClick={() => navigate("/")}
                className="botaoacoesvoltar"
                >
                  Voltar para o login
              </Button>
            
          </Grid>
        </Grid>
      </Styles.Footer>
      <ToastContainer />
    </FormFull>
  );
}

export default StepNewAccount;
