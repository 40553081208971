import React from "react";
import styled from "styled-components";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import { CustomText, FontStyles } from "../../../genericComponents";
import Styles from "../HomeStyles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import fonts from "../../../config/fonts";
import { MediaQueries } from "../../../config";
const Box = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    padding: spacing(3),
    boxShadow: "0px 24px 80px #2C4D7419",
    borderRadius: spacing(0.6),
    marginBottom: spacing(2),
  };
});

const Title = styled.p(({ theme, open }) => {
  const { palette: colors, spacing } = theme;
  return {
    padding: 0,
    margin: 0,
    ...FontStyles.bold22,
    color: open ? colors.text.secondary : colors.text.primary,
    opacity: open ? 1 : 0.5,
    marginBottom: spacing(2),
    [MediaQueries.smDown]: {
      ...FontStyles.bold18,
    },
  };
});
const Icon = styled(KeyboardArrowDownIcon)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    width: spacing(3),
    color: colors.background.alternative,
  };
});

const ClickArea = styled.div(({ theme }) => {
  return {
    width: "100%",
    cursor: "pointer",
  };
});

const TimeIcon = styled(QueryBuilderIcon)(({ theme }) => {
  const { spacing } = theme;
  return {
    width: spacing(2),
    height: spacing(2),
    marginRight: spacing(1),
  };
});

const ExtraContent = styled.div(({ theme, open }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    display: open ? "flex" : "none",
    paddingTop: spacing(3),
    fontFamily: fonts.regular,
    fontSize: spacing(2),
    color: "#2C4D74",
  };
});

function NoticeBox({ title, content, time, open = false, setOpen }) {
  const getHours = (date) => {
    let date1 = new Date();
    let date2 = new Date(date);
    var hours = Math.abs(date1 - date2) / 36e5;
    return hours > 24
      ? hours < 48
        ? Math.floor(hours / 24) + " dia"
        : Math.floor(hours / 24) + " dias"
      : hours.toFixed(0) + "horas";
  };
  return (
    <Box>
      <ClickArea onClick={() => setOpen()}>
        <Styles.CardHeader noLine>
          <Title open={open}>{title}</Title>
          <Icon />
        </Styles.CardHeader>
        <CustomText
          fontSize={2}
          fontFamily={fonts.regular}
          styles={{ color: "#C2C2C2", display: "flex", alignItems: "center" }}>
          <TimeIcon /> {getHours(time)}
        </CustomText>
      </ClickArea>
      <ExtraContent open={open}>
        {content.map((item) => {
          return (
            <>
              {item} <br />
            </>
          );
        })}
      </ExtraContent>
    </Box>
  );
}

export default NoticeBox;
