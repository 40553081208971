import React from "react";
import Styles from "./styles";
import { api } from "../../services";
import { CustomText, Footer } from "../../genericComponents";
import { Icons, Spacing } from "../../config";
import fonts from "../../config/fonts";

import { hooks } from "../../utils";

import NoticeBox from "./components/NoticeBox";
import { CircularProgress } from "@material-ui/core";

function Bulletins() {
  const { loading, call } = hooks.useRequest();

  const [news, setNews] = React.useState(null);
  const [oldNews, setOldNews] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);
  const [forMorePosts, setForMorePosts] = React.useState(true);

  const handleNotice = (id) => {
    if (id === open) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };

  const mount = React.useCallback(async () => {
    setForMorePosts(true);
    call(null, api.getBulletins(999), (response) => {
      if (response.ok) {
        let posts = [];
        let morePosts = [];

        response?.data?.content.map((item, index) => {
          if (index < 3) {
            posts.push({
              id: item.id,
              name: item.name,
              resume: item.resume.split("\n\n"),
              date: item.date,
              enable: item.enable,
            });
          }
          if (index >= 3 && index <= 5) {
            morePosts.push({
              id: item.id,
              name: item.name,
              resume: item.resume.split("\n\n"),
              date: item.date,
              enable: item.enable,
            });
          }
          return item;
        });

        setNews(posts);
        setOldNews(morePosts);
      }
    });
  }, [call]);

  React.useEffect(() => {
    if (!mounted) {
      mount();
      setMounted(true);
    }
  }, [mount, mounted]);

  const openMore = () => {
    setForMorePosts(false);
    call(null, api.getBulletins(999), (response) => {
      if (response.ok) {
        let morePosts = [];
        response?.data?.content.map((item, index) => {
          if (index >= 3) {
            morePosts.push({
              id: item.id,
              name: item.name,
              resume: item.resume.split("\n\n"),
              date: item.date,
              enable: item.enable,
            });
          }
          return item;
        });
        setOldNews(morePosts);
      }
    });
  };

  return (
    <Styles.Container>
      <Styles.Content>
        <Styles.Header>
          <CustomText
            fontSize={6}
            fontFamily={fonts.bold}
            textColor={1}
            styles={{ marginTop: 0, display: "flex", alignItems: "center" }}>
            <Icons.Megaphone
              style={{
                marginRight: Spacing(3),
                filter:
                  "invert(85%) sepia(100%) saturate(4488%) hue-rotate(246deg) brightness(90%) contrast(89%)",
              }}
            />{" "}
            Comunicados
          </CustomText>
        </Styles.Header>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <CustomText
              fontSize={3}
              fontFamily={fonts.bold}
              textColor={1}
              styles={{
                marginBlock: Spacing(3.5),
                display: "flex",
                alignItems: "center",
              }}>
              Comunicados recentes
            </CustomText>
            {news &&
              news?.map((item, index) => {
                return (
                  <NoticeBox
                    key={index}
                    title={item.name}
                    content={item?.resume}
                    time={item?.date}
                    open={item?.name === open}
                    setOpen={() => handleNotice(item?.name)}
                  />
                );
              })}

            <CustomText
              fontSize={3}
              fontFamily={fonts.bold}
              textColor={1}
              styles={{
                marginBlock: Spacing(3.5),
                display: "flex",
                alignItems: "center",
              }}>
              Comunicados antigos
            </CustomText>
            {oldNews &&
              oldNews?.map((item, index) => {
                return (
                  <NoticeBox
                    key={index}
                    title={item.name}
                    content={item?.resume}
                    time={item?.date}
                    open={item?.name === open}
                    setOpen={() => handleNotice(item?.name)}
                  />
                );
              })}
              {forMorePosts ? 
              <Styles.Header>
                <Styles.SmallButton
                  onClick={() => openMore()}
                  fullWidth={false}
                  label="Ver mais"
                />
              </Styles.Header> : <></>}
          </>
        )}
      </Styles.Content>
      <Footer />
    </Styles.Container>
  );
}

export default Bulletins;
