import React from "react";
import { Grid } from "@material-ui/core";
import ptBr from "../../../config/texts/pt-br";
import { ButtonContained, ButtonText } from "../../../genericComponents";
import Styles from "../ForgetPasswordStyles";
import {useNavigate} from 'react-router-dom';
function Completed({ RenderSwiperDots,  goBack, loading }) {
  const texts = ptBr.forgetPassword;
  const navigate = useNavigate();
  return (
    <>
      <Styles.Content className="corpoesqueci">
        <Styles.Title className="tituloesqueci">{texts.titleStepFour}</Styles.Title>
        <Styles.Subtitle className="subtituloesqueci"> {texts.subTitle[3]}</Styles.Subtitle>

        <ButtonContained
          name="continue"
          loading={loading}
          onClick={() => navigate('/')}
          label={texts.continue[3]}
          className="botaocriar"
        />
      </Styles.Content>

      <Styles.Footer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {RenderSwiperDots(texts.subTitle[4])}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "none", justifyContent: "space-between" }}>
            <ButtonText name="back" loading={loading} onClick={goBack} fullWidth={false}>
              <Styles.ArrowCircle>
                <Styles.ArrowLeft />
              </Styles.ArrowCircle>
              {texts.onBack}
            </ButtonText>
            <ButtonText name="next" loading={loading} disabled fullWidth={false}>
              {texts.onNext}
              <Styles.ArrowCircle>
                <Styles.ArrowRight />
              </Styles.ArrowCircle>
            </ButtonText>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "flex" }}>
            <ButtonText className="botaoacoesvoltar" onClick={() => navigate('/')} name="home"  loading={loading} fullWidth={false}>
              {texts.onBackMenu}
            </ButtonText>
          </Grid>
        </Grid>
      </Styles.Footer>
    </>
  );
}

export default Completed;
