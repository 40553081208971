import React from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Typography,
  FormControlLabel,
  withStyles,
} from "@material-ui/core";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FontStyles from "../styles/fontStyles";
import styled from "styled-components";
import { Colors } from "../../config";
import { useFormFull } from "form-full";

const StyledCheckbox = withStyles((theme) => ({
  root: {
    color: "lightGray",
    "& .MuiTouchRipple-rippleVisible": {
      color: "#5C42DB !important",
    },
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
  checked: {},
}))(Checkbox);

function CheckBox(props) {
  const { label, required, disableError, hideVisualError, click, register } =
    props;
  const { error, ref, valid } = useFormFull.field(props);

  const ErrorMessage = styled(FormHelperText)(({ theme }) => {
    return {
      color: "red",
    };
  });

  function getErrorClassname(base, error, valid) {
    const className = base;
    if (error) return `${className} invalid`;
    if (valid) return `${className} valid`;
    return className;
  }

  function getHint(error) {
    if (error) return error;
    return null;
  }

  return (
    <FormControl
      className={getErrorClassname("form-control", error, valid)}
      fullWidth
      error={hideVisualError ? null : Boolean(error)}>
      <FormControlLabel
        label={
          register ? (
            <Typography
              variant="h6"
              style={{ color: Colors.grayText, ...FontStyles.medium16 }}>
              Li e concordo com os
              <span
                onClick={() => {
                  window.open(
                    "https://www.ecomenergia.com.br/politica-de-privacidade/"
                  );
                }}
                style={{ color: Colors.grayText, ...FontStyles.bold16 }}>
                &nbsp;termos de uso
              </span>
            </Typography>
          ) : (
            <Typography
              variant="h6"
              style={{ color: Colors.grayText, ...FontStyles.medium16 }}>
              {label}
            </Typography>
          )
        }
        control={
          <StyledCheckbox
            icon={
              <CheckBoxOutlineBlankIcon style={{ fill: Colors.grayText }} />
            }
            checkedIcon={<CheckBoxIcon style={{ fill: Colors.yellow }} />}
            inputRef={ref}
            onChange={(a, b) => {
              click(b);
            }}
            labelPlacement="end"
            // className="form-input"
            required={!!required}
          />
        }
      />

      {disableError ? null : (
        <ErrorMessage error={Boolean(error)}>
          {getHint(error, valid)}
        </ErrorMessage>
      )}
    </FormControl>
  );
}

export default React.memo(CheckBox);
