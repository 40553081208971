import React from "react";
import Styles from "./SettingsStyles";
import SettingsIcon from "@material-ui/icons/Settings";
import { Colors } from "../../config";
import Items from "./items/items";
import ptBr from "../../config/texts/pt-br";
import { hooks, masks } from "../../utils";
import { api } from "../../services";
import { customModal } from "../../genericComponents/modals/utils";
import FormChangePassword from "./formChangePassword/formChangePassword";
import { Footer } from "../../genericComponents";

function Settings() {
  const texts = ptBr.settings;

  const { call } = hooks.useRequest();
  const [formData, setFormData] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);

  const mount = React.useCallback(() => {
    call(null, api.getUserData(), (response) => {
      if (response.ok) {
        setFormData(response?.data);
      } else {
      }
    });
  }, [call]);

  React.useEffect(() => {
    if (!mounted) {
      mount();
      setMounted(true);
    }
  }, [mount, mounted]);

  const cpfOrCnpj = (value) => {
    let newLabel;
    let newValue;
    if (value.length <= 11) {
      newLabel = "CPF";
      newValue = masks.CPF(value);
    } else {
      newLabel = "CNPJ";
      newValue = masks.CNPJ(value);
    }
    return [newLabel, newValue];
  };

  function changePassword() {
    customModal.setInfos(
      "Redefinir Senha",
      ["Redefina sua senha inserindo abaixo os dados solicitados."],
      null,
      null,
      <FormChangePassword
        close={() => {
          customModal.close();
        }}
      />
    );
  }

  return (
    <>
      {formData ? (
        <>
          <Styles.Container>
            <Styles.ContentTitle>
              <SettingsIcon
                style={{
                  color: Colors.purple,
                  width: 32,
                  height: 32,
                }}
              />
              <Styles.Title>{texts.title}</Styles.Title>
            </Styles.ContentTitle>
            <Styles.Dot>
              <Styles.Letter>{formData?.nickname.substr(0, 1)}</Styles.Letter>
            </Styles.Dot>
            <Items subTitle={texts.subTitle[0]} text={formData?.nickname} />
            <Items subTitle={texts.subTitle[1]} text={formData?.nickname} />
            <Items
              subTitle={cpfOrCnpj(formData?.cpfCnpj)[0]}
              text={cpfOrCnpj(formData?.cpfCnpj)[1]}
            />
            <Items
              subTitle={texts.subTitle[2]}
              text="******"
              edit
              onClick={() => {
                changePassword();
              }}
            />
            <Footer />
          </Styles.Container>
        </>
      ) : null}
    </>
  );
}

export default Settings;
