import React, { Component } from "react";
import Input from "./Input";
import { masks } from "../../utils";

export default class InputCNPJandCPF extends Component {
  render() {
    const { maxLength = 18, ...props } = this.props;

    return (
      <Input
        {...props}
        mask={masks.CPFCNPJ}
        maxLength={maxLength}
      />
    );
  }
}
