import { IconButton } from "@material-ui/core";
import styled from "styled-components";
import { Colors, Icons, Spacing } from "../../../config";
import FontStyles from "../../../genericComponents/styles/fontStyles";

const Container = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: Spacing(0.01),
    paddingBottom: Spacing(1.5),
  };
});

const SubTitle = styled.p(() => {
  return {
    color: Colors.purple,
    ...FontStyles.bold22,
    paddingBottom: -30,
  };
});

const Text = styled.p(() => {
  return {
    color: Colors.grayText,
    ...FontStyles.medium16,
  };
});

const TextContent = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "baseline",
  };
});

const ButtonIcon = styled(IconButton)(() => {
  return {
    padding: 0,
    marginLeft: Spacing(1),
    "& .MuiTouchRipple-root": {
      color: "transparent !important",
    },
  };
});

const EditIcon = styled(Icons.Edit)(() => {
  return {
    width: 18,
    height: "auto",
  };
});

const Styles = {
  Container,
  SubTitle,
  Text,
  TextContent,
  EditIcon,
  ButtonIcon,
};

export default Styles;
