import { create } from "apisauce";
import caller from "./helpers/caller";

import { SessionStorage } from "../utils";
import models from "./Models";

const apiJava = process.env.REACT_APP_API_JAVA;
const apiMiddleware = process.env.REACT_APP_API_MIDDLEWARE;
const tokenLogin = process.env.REACT_APP_TOKEN_LOGIN;
const tokenSenha = process.env.REACT_APP_TOKEN_SENHA;


const api = create({
  baseURL: apiJava,
  timeout: 500000,
});

const api_laravel = create({
  baseURL: apiMiddleware,
  timeout: 500000
});


const api_cep = create({
  baseURL: 'https://viacep.com.br/ws/',
  timeout: 500000,
});

api_laravel.addRequestTransform((request) => {
  const middleware_token = SessionStorage.getItem("middleware_token");

  if (middleware_token) {
    request.headers.authorization = middleware_token;
  }
});

api.addRequestTransform((request) => {
  const token = SessionStorage.getItem("token");

  if (token) {
    request.headers.authorization = token;
  }
});

api.addMonitor((response) => {
  const token = response.headers.authorization;

  if (token) {
    SessionStorage.setItem("token", token);
  }
});

async function getToken(loginData) {
  return caller(api.post, "/public/sign-in", null, loginData);
}
async function sendRecoveryPasswordRequest(data) {
  return caller(api.post, `/public/password-reset`, null, data);
}

async function recoveryPasswordValidate(data) {
  //validate email code
  return caller(api.post, "/public/password-reset/validate", null, data);
}

async function sendRecoveryPasswordNewPassword(data) {
  //password reset
  return caller(api.post, "/public/password-reset/confirm", null, data);
}

async function changePassword(data) {
  return caller(api.post, "/user/change_password", null, data);
}

async function postRegistration(data) {
  //register
  return caller(api.post, "/public/sign-up/request", null, data);
}

async function postValidation(data) {
  //validation register
  return caller(api.post, "/public/sign-up/validate", null, data);
}

async function postConfirmRegistration(data) {
  //confirm register
  return caller(api.post, "/public/sign-up/confirm", null, data);
}

async function getUserData(data) {
  //confirm register
  return caller(api.get, "/users/me", null, data);
}

async function getBulletins(size = 4) {
  //confirm register
  return caller(api.get, `/bulletins?page=0&size=${size}`, null, null);
}

async function getPLD(id) {
  return models.DecimalLineChart({
    data: [210, 390, 420, 550, 205, 415, 380, 200, 400, 250, 600],
    label: [
      "10/01",
      "11/01",
      "12/01",
      "13/01",
      "10/01",
      "10/01",
      "10/01",
      "10/01",
      "10/01",
      "10/01",
      "10/01",
    ],
  });
}

async function getAllPLD(data) {
  //confirm register
  return caller(api.get, "/public/pld/months", null, null);
}

async function getReportType(data) {
  //confirm register
  return caller(api.get, "/public/report-types", null, null);
}

async function getAllReport(data) {
  //confirm register
  return caller(api.get, "/reports", null, null);
}

async function getPDF(data) {
  //confirm register
  return caller(api.get, `/public/reports/${data}/pdf`, null, null, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
    },
  });
}

async function putChangePassword(data) {
  //confirm register
  return caller(api.put, "/users/me/password", null, data);
}

async function getNotifications(data) {
  return caller(api.get, "/notifications", null, null);
}

async function deleteAllNotifications(data) {
  return caller(api.delete, "/notifications", null, data);
}

async function deleteNotification(id) {
  return caller(api.delete, `/notifications/${id}`, null, null);
}


async function getVlrEmpresasCadastradas(user_id) {
  return caller(api_laravel.get, "/api/parceiros/vlrEmpresasCadastradas/"+user_id, null, null);
}
async function getVlrIndicacoes(user_id) {
  return caller(api_laravel.get, "/api/parceiros/vlrIndicacoes/"+user_id, null, null);
}
async function getVlrIndicacoesEmAndamento(user_id) {
  return caller(api_laravel.get, "/api/parceiros/vlrIndicacoesEmAndamento/"+user_id, null, null);
}
async function getVlrClientesFechados(user_id) {
  return caller(api_laravel.get, "/api/parceiros/vlrClientesFechados/"+user_id, null, null);
}
async function getVlrNegocioPerdido(user_id) {
  return caller(api_laravel.get, "/api/parceiros/vlrNegocioPerdido/"+user_id, null, null);
}
async function valoresIndicacoes(user_id) {
  return caller(api_laravel.get, "/api/parceiros/valoresIndicacoes/"+user_id, null, null);
}

async function getEmpresasCadastradas(user_id) {
  return caller(api_laravel.get, "/api/parceiros/empresasCadastradas/"+user_id, null, null);
}
async function getIndicacoes(user_id) {
  return caller(api_laravel.get, "/api/parceiros/indicacoes/"+user_id, null, null);
}
async function getIndicacoesValidadas(user_id) {
  return caller(api_laravel.get, "/api/parceiros/indicacoesValidadas/"+user_id, null, null);
}
async function getIndicacoesEmAndamento(user_id) {
  return caller(api_laravel.get, "/api/parceiros/indicacoesEmAndamento/"+user_id, null, null);
}
async function getClientesFechados(user_id) {
  return caller(api_laravel.get, "/api/parceiros/clientesFechados/"+user_id, null, null);
}
async function getNegocioPerdido(user_id) {
  return caller(api_laravel.get, "/api/parceiros/contaNegocioPerdido/"+user_id, null, null);
}
async function getDadosGrafico(user_id) {
  return caller(api_laravel.get, "/api/parceiros/dadosGrafico/"+user_id, null, null);
}
async function getDadosDashboard(user_id) {
  return caller(api_laravel.get, "/api/parceiros/dadosDashboard/"+user_id, null, null);
}
async function parceirosIndicacoes(user_id, data) {
  return caller(api_laravel.post, "/api/parceiros/"+user_id, null, data);
}
async function dadosDoParceiro(user_id, data) {
  return caller(api_laravel.get, "/api/parceiros/dadosDoParceiro/"+user_id, null, data);
}
async function cadastroIndicacao(user_id, data) {
  return caller(api_laravel.post, "/api/parceiros/cadastroIndicacao/"+user_id, null, data);
}
async function editarIndicacao(indicacao_parceiro_id, data) {
  return caller(api_laravel.post, "/api/parceiros/editarIndicacao/"+indicacao_parceiro_id, null, data);
}
async function cadastroUnidadeConsumidora(indicacao_parceiro_id, data) {
  return caller(api_laravel.post, "/api/parceiros/cadastroUnidadeConsumidora/"+indicacao_parceiro_id, null, data);
}
async function editarUnidadeConsumidora(parceiro_unidade_consumidora_id, data) {
  return caller(api_laravel.post, "/api/parceiros/editarUnidadeConsumidora/"+parceiro_unidade_consumidora_id, null, data);
}
async function listaUnidadesConsumidoras(indicacao_parceiro_id) {
  return caller(api_laravel.get, "/api/parceiros/listaUnidadesConsumidoras/"+indicacao_parceiro_id, null, null);
}
async function pegaUnidadeConsumidora(parceiro_unidade_consumidora_id) {
  return caller(api_laravel.get, "/api/parceiros/pegaUnidadeConsumidora/"+parceiro_unidade_consumidora_id, null, null);
}
async function listaEmpresas(user_id) {
  return caller(api_laravel.get, "/api/parceiros/listaEmpresas/"+user_id, null, null);
}
async function getEmpresas(empresa_id) {
  return caller(api_laravel.get, "/api/parceiros/getEmpresas/"+empresa_id, null, null);
}

async function enviaArquivo(data) {
  console.log(data);
  return caller(api_laravel.post, "/api/uploadDocumentosParceiro", null, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

async function verificaDocumentos(parceiro_id, tipo_id) {
  return caller(api_laravel.get, "/api/verificaDocumentosEnviados/"+parceiro_id+"/"+tipo_id, null, null);
}
async function verificaQtdDocumentos(parceiro_id) {
  return caller(api_laravel.get, "/api/verificaQtdDocumentos/"+parceiro_id, null, null);
}

async function lerFatura(data) {
  
  return caller(api_laravel.post, "/api/4Docs/lerFatura", null, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}
async function enviaFatura(data) {
  
  return caller(api_laravel.post, "/api/4Docs/enviaFatura", null, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}
async function enviaFaturaSimulacao(data) {
  
  return caller(api_laravel.post, "/api/4Docs/enviaFaturaSimulacao", null, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}
async function enviaCaptura(data) {
  
  return caller(api_laravel.post, "/api/4Docs/enviaCaptura", null, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

async function pegaDados(data) {
  return caller(api_laravel.post, "/api/4Docs/pegaDados", null, data);
}
async function verDocumento(id) {
  return caller(api_laravel.get, "/api/downloadDocumentos/"+id, null, null);
}
async function pegaItens(data) {
  return caller(api_laravel.post, "/api/4Docs/pegaItens", null, data);
}
async function pegaHistorico(data) {
  return caller(api_laravel.post, "/api/4Docs/pegaHistorico", null, data);
}

async function verificaFaturasEnviadas(user_id, tipo_id, unidade_consumidora_id) {
  return caller(api_laravel.post, "/api/verificaFaturasEnviadas/"+user_id+"/"+tipo_id+"/"+unidade_consumidora_id, null, null);
}

async function calcularViabilidade(distribuidora, tensao, bandeira, cep) {
  return caller(api_laravel.post, "/api/calcularViabilidade/"+distribuidora+"/"+tensao+"/"+bandeira+"/"+cep, null, null);
}

async function calcularViabilidadePorId(unidade_consumidora_id) {
  return caller(api_laravel.post, "/api/calcularViabilidadePorId/"+unidade_consumidora_id, null, null);
}
async function cadastrarProduto(unidade_consumidora_id, data) {
  return caller(api_laravel.post, "/api/cadastrarProduto/"+unidade_consumidora_id, null, data);
}
async function cadastrarProdutoSimplificado(unidade_consumidora_id, data) {
  return caller(api_laravel.post, "/api/cadastrarProdutoSimplificado/"+unidade_consumidora_id, null, data);
}
async function verificaStatusParceiro(documento, email) {
  return caller(api_laravel.post, "/api/verificaParceiroStatus/"+documento+'/'+email, null, null);
}
async function listaDistribuidoras() {
  return caller(api_laravel.get, "/api/listaDistribuidoras", null, null);
}
async function listaBandeiras() {
  return caller(api_laravel.get, "/api/listaBandeiras", null, null);
}
async function listaSubgrupo() {
  return caller(api_laravel.get, "/api/listaSubgrupo", null, null);
}
async function listaBandeirasPorDistribuidora(distribuidor_cnpj) {
  return caller(api_laravel.post, "/api/listaBandeirasPorDistribuidora/"+distribuidor_cnpj, null, null);
}
async function listaSubgrupoPorDistribuidora(distribuidor_cnpj) {
  return caller(api_laravel.post, "/api/listaSubgrupoPorDistribuidora/"+distribuidor_cnpj, null, null);
}
async function listaBandeirasPorDistribuidoraSubgrupo(distribuidor_cnpj, tarifa_subgrupo) {
  return caller(api_laravel.post, "/api/listaBandeirasPorDistribuidoraSubgrupo/"+distribuidor_cnpj+"/"+tarifa_subgrupo, null, null);
}

async function editarDadosParceiro(parceiro_id, data) {
  return caller(api_laravel.post, "/api/parceiros/editarDadosParceiro/"+parceiro_id, null, data);
}

async function listaAreaAtuacao() {
  return caller(api_laravel.get, "/api/listaAreaAtuacao", null, null);
}
async function listaComoChegou() {
  return caller(api_laravel.post, "/api/listaComoChegou", null, null);
}
async function listaMunicipios(uf='') {
  return caller(api_laravel.get, "/api/listaMunicipios/"+uf, null, null);
}

async function pegamunicipios(parceiro_id) {
  return caller(api_laravel.get, "/api/pegamunicipios/"+parceiro_id, null, null);
}

async function cadastroMultiplosMunicipios(parceiro_id, data) {
  return caller(api_laravel.post, "/api/cadastroMultiplosMunicipios/"+parceiro_id, null, data);
}

async function pegaoestado(cep) {
  return caller(api_cep.get, cep+"/json", null, null);
}

async function verificaCNPJPorIndicacao(indicacao_parceiro_id, data) {
  return caller(api_laravel.post, "/api/parceiros/verificaCNPJPorIndicacao/"+indicacao_parceiro_id, null, data);
}

async function cadastrorepresentante(data) {
  return caller(api_laravel.post, "/api/parceiro/representante", null, data);
}

async function alertaCNPJ(cnpj) {
  return caller(api_laravel.get, "/api/alertaCNPJ/"+cnpj, null, null);
}

async function modalAssinatura() {
  return caller(api_laravel.get, "/api/modalAssinatura", null, null);
}

async function listarepresentantes(parceiro_id) {
  return caller(api_laravel.get, "/api/parceiro/representantes/"+parceiro_id, null, null);
}

async function excluirepresentante(data) {
  return caller(api_laravel.post, "/api/parceiro/representante/exclui", null, data);
}

async function pegaComissaoNegocioFechado(unidade_consumidora_id) {
  return caller(api_laravel.get, "/api/getComissaoNegocioFechado/"+unidade_consumidora_id, null, null);
}

async function gravaLog(data) {
  return caller(api_laravel.post, "/api/log/front", null, data);
}
async function pegaToken() {
  var data = {'email': tokenLogin, 'password': tokenSenha};
  return caller(api_laravel.post, "/api/login", null, data);
}

async function cadastrorepresentanteuc(data) {
  return caller(api_laravel.post, "/api/parceiro/representanteuc", null, data);
}
async function listarepresentantesuc(parceiro_unidade_consumidora_id) {
  return caller(api_laravel.get, "/api/parceiro/representantesuc/"+parceiro_unidade_consumidora_id, null, null);
}

async function excluirepresentanteuc(data) {
  return caller(api_laravel.post, "/api/parceiro/representanteuc/exclui", null, data);
}


async function enviaArquivouc(data) {
  console.log(data);
  return caller(api_laravel.post, "/api/uploadDocumentosParceirouc", null, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

async function verificaDocumentosuc(parceiro_unidade_consumidora_id) {
  return caller(api_laravel.get, "/api/verificaDocumentosEnviadosuc/"+parceiro_unidade_consumidora_id, null, null);
}

async function enviarassinaturacompravenda(parceiro_unidade_consumidora_id) {
  return caller(api_laravel.get, "/api/docusign/enviarassinaturacompravenda/"+parceiro_unidade_consumidora_id, null, null);
}

async function pegarEnvelopecompravenda(parceiro_unidade_consumidora_id) {
  return caller(api_laravel.get, "/api/docusign/pegarEnvelopecompravenda/"+parceiro_unidade_consumidora_id, null, null);
}

async function statusAssinaturascompravenda(envelope_id) {
  return caller(api_laravel.get, "/api/docusign/statusAssinaturascompravenda/"+envelope_id, null, null);
}

async function enviarassinaturacompravendaZapsign(parceiro_unidade_consumidora_id) {
  return caller(api_laravel.get, "/api/zapsign/enviarassinaturacompravenda/"+parceiro_unidade_consumidora_id, null, null);
}

async function pegarEnvelopecompravendaZapsign(parceiro_unidade_consumidora_id) {
  return caller(api_laravel.get, "/api/zapsign/pegarEnvelopecompravenda/"+parceiro_unidade_consumidora_id, null, null);
}

async function statusAssinaturascompravendaZapsign(envelope_id) {
  return caller(api_laravel.get, "/api/zapsign/statusAssinaturascompravenda/"+envelope_id, null, null);
}

async function verificaStatusAssinaturasCompraVenda(parceiro_unidade_consumidora_id) {
  return caller(api_laravel.get, "/api/verificaStatusAssinaturasCompraVenda/"+parceiro_unidade_consumidora_id, null, null);
}

async function verificaSSO(data) {
  return caller(api_laravel.post, "/api/verificaSSO", null, data);
}

async function calcularViabilidadePorSimulacao(data) {
  return caller(api_laravel.post, "/api/calcularViabilidadePorSimulacao", null, data);
}

async function verificaStatusAssinaturaParceiro(parceiro_id) {
  return caller(api_laravel.get, "/api/verificaStatusAssinaturaParceiro/" + parceiro_id, null, null);
}

async function statusAssinaturasPorUsuario(user_id) {
  return caller(api_laravel.get, "/api/docusign/statusAssinaturasPorUsuario/" + user_id, null, null);
}

async function pegaParametroEcom(parametro) {
  return caller(api_laravel.get, "/api/pegaParametroEcom/" + parametro, null, null);
}

const apiServices = {
  sendRecoveryPasswordRequest,
  sendRecoveryPasswordNewPassword,
  recoveryPasswordValidate,
  changePassword,
  getToken,
  postRegistration,
  postValidation,
  postConfirmRegistration,
  getPLD,
  getUserData,
  getAllPLD,
  getBulletins,
  getReportType,
  getAllReport,
  getPDF,
  putChangePassword,
  getNotifications,
  deleteAllNotifications,
  deleteNotification,
  getVlrClientesFechados,
  getClientesFechados,
  getVlrEmpresasCadastradas,
  getEmpresasCadastradas,
  getIndicacoes,
  getVlrIndicacoes,
  getIndicacoesValidadas,
  getIndicacoesEmAndamento,
  getVlrIndicacoesEmAndamento,
  parceirosIndicacoes,
  dadosDoParceiro,
  cadastroIndicacao,
  cadastroUnidadeConsumidora,
  listaUnidadesConsumidoras,
  listaEmpresas,
  getEmpresas,
  editarUnidadeConsumidora,
  enviaArquivo,
  verificaDocumentos,
  lerFatura,
  enviaFatura,
  pegaDados,
  pegaItens,
  pegaHistorico,
  verificaFaturasEnviadas,
  calcularViabilidade,
  calcularViabilidadePorId,
  cadastrarProduto,
  verificaStatusParceiro,
  listaDistribuidoras,
  listaBandeiras,
  listaSubgrupo,
  listaBandeirasPorDistribuidora,
  listaSubgrupoPorDistribuidora,
  listaBandeirasPorDistribuidoraSubgrupo,
  editarDadosParceiro,
  listaAreaAtuacao,
  listaComoChegou,
  pegaoestado,
  pegaUnidadeConsumidora,
  verificaQtdDocumentos,
  verDocumento,
  listaMunicipios,
  pegamunicipios,
  cadastroMultiplosMunicipios,
  getNegocioPerdido,
  getVlrNegocioPerdido,
  alertaCNPJ,
  cadastrorepresentante,
  listarepresentantes,
  excluirepresentante,
  modalAssinatura,
  pegaComissaoNegocioFechado,
  gravaLog,
  pegaToken,
  cadastrorepresentanteuc,
  listarepresentantesuc,
  excluirepresentanteuc,
  enviaArquivouc,
  verificaDocumentosuc,
  enviarassinaturacompravenda,
  pegarEnvelopecompravenda,
  statusAssinaturascompravenda,
  verificaStatusAssinaturasCompraVenda,
  verificaSSO,
  verificaStatusAssinaturaParceiro,
  statusAssinaturasPorUsuario,
  pegaParametroEcom,
  editarIndicacao,
  enviarassinaturacompravendaZapsign,
  pegarEnvelopecompravendaZapsign,
  statusAssinaturascompravendaZapsign,
  enviaCaptura,
  getDadosGrafico,
  valoresIndicacoes,
  getDadosDashboard,
  enviaFaturaSimulacao,
  calcularViabilidadePorSimulacao,
  cadastrarProdutoSimplificado
};

export default apiServices;
