import React from "react";
import Styles from "./styles";
import { api } from "../../services";
import { CustomText, Footer } from "../../genericComponents";
import { Icons, Spacing } from "../../config";
import fonts from "../../config/fonts";

import { hooks } from "../../utils";

import ReportBox from "./components/ReportBox";
import { CircularProgress, Grid } from "@material-ui/core";
const GridWithSSM = ({ ssm, ...other }) => {
  const ssmClass = `MuiGrid-grid-ssm-${ssm}`;
  return <Grid className={ssmClass} {...other} />;
};
function Reports() {
  const { loading, call } = hooks.useRequest();

  const [reportTypes, SetReportTypes] = React.useState(null);
  const [listReports, setReports] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);
  const mount = React.useCallback(async () => {
    call("ReportTypes", api.getReportType(), (response) => {
      if (response.ok) {
        let reportsType = {};
        let reportsList = [];
        response?.data?.content.forEach((item) => {
          reportsType[item.id] = {
            icon: item.image,
            title: item.name,
            subTitle: item.category,
            data: item.dateIssuance,
          };
          reportsList.push({
            icon: item.image,
            title: item.name,
            subTitle: item.category,
            data: item.dateIssuance,
            id: item.id,
          });
        });

        call("AllReports", api.getAllReport(), (data) => {
          if (data.ok) {
            let reports = [];
            data?.data?.content.forEach((item, index) => {
              let type = reportsType[item.reportTypeId];

              let objIndex = reports.findIndex(
                (obj) => obj.reportTypeId === item.reportTypeId
              );

              if (objIndex !== -1) {
                reports[objIndex].reportList.push({
                  id: item.id,
                  link: item.link,
                  date: item.dateReference,
                });
                reports[objIndex].options.push({
                  value: item.id,
                  name: formatDate(item.dateReference),
                  label: formatDate(item.dateReference),
                  trueDate: item.dateReference,
                });
              } else {
                reports.push({
                  reportTypeId: item.reportTypeId,
                  icon: type.icon,
                  title: type.title,
                  subTitle: type.subTitle,
                  reportList: [
                    {
                      id: item.id,
                      link: item.link,
                      date: item.dateReference,
                    },
                  ],
                  options: [
                    {
                      value: item.id,
                      label: formatDate(item.dateReference),
                      trueDate: item.dateReference,
                      name: formatDate(item.dateReference),
                    },
                  ],
                });
              }
            });

            setReports(reports);
            reportsList.sort((a, b) => {
              if (
                parseInt(a.subTitle.replace("C", "")) >
                parseInt(b.subTitle.replace("C", ""))
              ) {
                return 1;
              } else if (
                parseInt(a.subTitle.replace("C", "")) <
                parseInt(b.subTitle.replace("C", ""))
              ) {
                return -1;
              }
              return 0;
            });

            SetReportTypes(reportsList);
          }
        });
      }
    });
  }, [call]);

  React.useEffect(() => {
    if (!mounted) {
      mount();
      setMounted(true);
    }
  }, [mount, mounted]);

  function formatDate(input) {
    let datePart = input.match(/\d+/g),
      year = datePart[0].substring(2),
      month = datePart[1];

    return month + "/" + year;
  }

  const getList = (id, listReports) => {
    const list = listReports.find((item) => {
      if (item?.reportTypeId === id) {
        let newItem = item;
        const newOrder = item?.options?.sort((a, b) => {
          if (
            new Date(a?.trueDate).getTime() > new Date(b?.trueDate).getTime()
          ) {
            return -1;
          }
          if (
            new Date(a?.trueDate).getTime() < new Date(b?.trueDate).getTime()
          ) {
            return 1;
          }
          return 0;
        });

        newItem.options = newOrder;

        return newItem;
      } else {
        return false;
      }
    });

    return list !== undefined ? list : false;
  };

  var viewport_width = document.documentElement.clientWidth;
  return (
    <Styles.Container>
      <Styles.Content>
        <Styles.Header>
          <CustomText
            fontSize={viewport_width > 600 ? 6 : 4}
            fontFamily={fonts.bold}
            textColor={1}
            styles={{ marginTop: 0, display: "flex", alignItems: "center" }}>
            <Icons.Clipboard
              style={{
                marginRight: Spacing(3),
                filter:
                  "invert(85%) sepia(23%) saturate(4488%) hue-rotate(246deg) brightness(90%) contrast(89%)",
              }}
            />
            Relatórios
          </CustomText>
        </Styles.Header>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={3}>
            {reportTypes?.length > 0 &&
              listReports?.length > 0 &&
              reportTypes?.map((item, index) => {
                if (getList(item?.id, listReports)) {
                  return (
                    <GridWithSSM
                      item
                      xs={12}
                      ssm={6}
                      sm={6}
                      md={4}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <ReportBox
                        key={index}
                        item={item}
                        listData={getList(item?.id, listReports)}
                      />
                    </GridWithSSM>
                  );
                } else {
                  return null;
                }
              })}
          </Grid>
        )}
      </Styles.Content>
      <Footer />
    </Styles.Container>
  );
}

export default Reports;
