import React, { useState, useEffect } from "react";
import { createContext } from "react";
import { SessionStorage } from "../utils";

export const StorageContext = createContext({});

export default function StorageProvider({ children }) {
  const [data, setData] = useState({});
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const token = SessionStorage.getItem("token");
    if (token) {
      setIsLogged(true);
    }
  }, [setIsLogged]);

  function logOut() {
    removeData("token");
    SessionStorage.removeItem("token");
    setIsLogged(false);
  }

  function addData(key, value) {
    setData((state) => ({ ...state, [key]: value }));
  }

  function removeData(key) {
    setData((state) => {
      const newState = state;
      delete newState[key];
      return newState;
    });
  }

  function getData(key) {
    return data[key];
  }

  return (
    <StorageContext.Provider
      value={{
        addData,
        removeData,
        getData,
        isLogged,
        setIsLogged,
        logOut,
      }}>
      {children}
    </StorageContext.Provider>
  );
}
