import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import ptBr from "../../config/texts/pt-br";
import images from "../../config/images";
import {
  ButtonContained,
  CheckBox,
  CustomText,
  InputEmail,
  InputPassword,
} from "../../genericComponents";
import Styles from "./LoginStyles";
import { FormFull } from "form-full";
import { MediaQueries, Spacing } from "../../config";
import { validations } from "../../utils";
import { api } from "../../services";
import { hooks, SessionStorage } from "../../utils";
import { StorageContext } from "../../contexts/StorageContext";
import ImageLogin from "./image/imageLogin";
import ImageLogin2 from "./image/imageLogin2";
import packageJson from "../../../package.json";
import fonts from "../../config/fonts";
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";


  

export default function LoginSso() {
    const [reminder, setReminder] = React.useState(false);
    const [buttonDisabled, setButton] = React.useState(true);
    const [ssovalido, setssovalido] = React.useState('carregando');
    const [mounted, setMounted] = React.useState(false);
    

    const texts = ptBr.login;
    const version = packageJson.version;
    const navigate = useNavigate();
    const { loading, call } = hooks.useRequest();
    const { addData, setIsLogged } = useContext(StorageContext);
    let { tokensso, chave } = useParams();

    const mount = React.useCallback(async () => {
        if((typeof tokensso != undefined) 
            && (typeof chave != undefined)
        ) {
              SessionStorage.setItem(
                "middleware_token",
                "Bearer" + " " + tokensso
              );
              console.log("LARAVEL TOKEN: " + "Bearer" + " " + tokensso);
              const formDados = new FormData();
              formDados.append("chave", chave);  
              call(null, api.verificaSSO(formDados), (sso) => {
                if(sso.ok) {
                    console.log(sso?.data);
                    if(sso?.data) {
                        if(sso.data != 'Acesso não permitido') {
                            console.log(sso?.data);
                            setIsLogged(true);
                            addData("userData", sso?.data?.user);
                            SessionStorage.setItem(
                                "token",
                                "Bearer" + " " + tokensso
                              );
                            SessionStorage.setItem(
                                "user_id",
                                sso?.data.id
                            );
                            setssovalido(true);
                            navigate("/");
                        } else{
                            setssovalido('invalido');
                        }
                    }
                } else {
                  setssovalido('invalido');
                  console.log('Ocorreu um erro ao validar o status do parceiro, entre em contato com o administrador do sistema.');      
                }
              });

        } 
    }, [call]);

    React.useEffect(() => {
    if (!mounted) {
        mount();
        setMounted(true);
    }
    }, [mount, mounted]);
  
    const toastOps = (message) => {
      toast.warning(message, {
          position: toast.POSITION.TOP_CENTER
      });
    };
  
    return (
        <div>
        {
            (ssovalido == 'carregando')
            &&
            <h1>Aguarde , estamos validando a sua requisição</h1>
        }
        {
            (ssovalido == 'invalido')
            &&
            <h1>Ocorreu um erro ao validar o acesso ao parceiro, entre em contato com o administrador do sistema.</h1>
        }
        </div>
      
    );
}

