import styled from "styled-components";
import colors from "../../config/colors";
import fonts from "../../config/fonts";
import { Colors, MediaQueries, Spacing } from "../../config";
import { ButtonContained, FontStyles } from "../../genericComponents";
import { MenuItem, Select } from "@material-ui/core";
import { ReactComponent as Pin } from "../../assets/svg/icone-flecha-select.svg";
import { IconButton } from "@material-ui/core";

const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    width: "100vw",
    height: `calc(100vh - ${spacing(17)}px)`,
    flexDirection: "column",
    paddingLeft: "3rem",
    paddingRight: "3rem",
    justifyContent: "flex-start",
    alignItems: "center",
    overflowY: "auto",
    overflowX: "hidden",
  };
});

const Content = styled.div(({ theme, alternative, marginTop, $Image }) => {
  return {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    width: `100%`,
    backgroundColor: alternative ? "yellow" : "transparent",
    paddingInline: "13%",
    
    [MediaQueries.mdDown]: {
      paddingInline: Spacing(4),
      paddingBlock: Spacing(8),
    },
    backgroundImage: `url(${$Image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
});

const ImageBackground = styled.div(({ $Image }) => {
  return {
    width: "100%",
    height: "100%",
    backgroundColor: "pink",
    backgroundImage: $Image,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // [MediaQueries.xlDown]: {
    //   paddingTop: Spacing(5),
    // },
  };
});

const Header = styled.div(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: "32rem",
    height: "fit-content",
    marginBottom: Spacing(3),
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
  };
});

const Title = styled.p(({ theme }) => {
  // const { palette: colors } = theme;
  return {
    color: colors.purple,
    opacity: 0.88,
    margin: 0,
    textAlign: "left",
    ...FontStyles.bold42,
    paddingTop: Spacing(8),
  };
});

const Subtitle = styled.p(({ theme }) => {
  // const { palette: colors } = theme;
  return {
    color: colors.grayText,
    ...FontStyles.medium16,
    opacity: 0.88,
    margin: 0,
    paddingTop: Spacing(2.5),
    paddingBottom: Spacing(3),
  };
});

const EcomLogo = styled.img(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: Spacing(15),
    height: "auto",
  };
});

const P = styled.p(({ theme, gray }) => {
  // const { spacing } = theme;
  return {
    fontFamily: gray ? fonts.medium : fonts.bold,
    color: gray ? colors.grayText : colors.purple,
    fontSize: fonts.sizes.regular,
  };
});

const Span = styled.span(({ theme }) => {
  // const { spacing } = theme;
  return {
    fontFamily: fonts.bold,
    color: colors.purple,
    fontSize: fonts.sizes.regular,
    marginLeft: 3,
  };
});

//----

const Card = styled.div(({ $Image }) => {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    border: "1px solid #EFEFEF",
    borderRadius: Spacing(0.6),
  };
});
const CardHeader = styled.div(({ theme, noLine }) => {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: !noLine && Spacing(4),
    borderBottom: !noLine && "1px solid #EFEFEF",
  };
});
const CardTitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    margin: 0,
    padding: 0,
    color: colors.text.secondary,
    ...FontStyles.bold22,
  };
});
const Block = styled.div(({ theme }) => {
  return {
    padding: Spacing(4),
  };
});
const SelectLabel = styled.p(({ theme }) => {
  return {
    margin: 0,
    padding: 0,
    marginRight: Spacing(1),
    color: Colors.grayText,
    textTransform: "capitalize",
    ...FontStyles.bold22,
  };
});
const SelectRegion = styled(Select)(() => {
  return {
    margin: 0,
    padding: 0,
    color: "#C2C2C2",

    ...FontStyles.bold22,
    "& .MuiSelect-select": {
      padding: 0,
    },
    "& .MuiSvgIcon-root": {
      color: "#C2C2C2",
  },
    [MediaQueries.smDown]: {
      ...FontStyles.bold18,
    },
  };
});

const SelectIcon = styled(Pin)(() => {
  return {
    width: Spacing(3),
    height: Spacing(3),
    fill: "#C2C2C2",
    [MediaQueries.smDown]: {
      width: Spacing(1.75),
      height: Spacing(1.75),
    },
  };
});
const Footer = styled.p(({ theme }) => {
  return {
    margin: 0,
    marginTop: Spacing(1),
    padding: 0,
    width: "100%",
    textAlign: "center",
    color: Colors.grayText,
    opacity: 0.8,
    ...FontStyles.italic14,
  };
});
const ShadowCard = styled.div(({ theme }) => {
  return {
    marginTop: Spacing(6),

    width: "100%",
    height: "100%",
    background: "#5C42DB 0% 0% no-repeat padding-box",
    boxShadow: `0px ${Spacing(10.5)}px ${Spacing(7.5)}px #2C4D741A`,
    borderRadius: Spacing(0.6),
    padding: Spacing(5.5),
  };
});

const Row = styled.div(({ theme, centralized }) => {
  return {
    width: "100%",
    display: "flex",
    flexDirection: "row",

    justifyContent: centralized ? "center" : "space-between",
    alignItems: "center",
    [MediaQueries.mdDown]: {
      justifyContent: "flex-start",
      overflowX: "auto",
    },
    [MediaQueries.smDown]: {
      flexDirection: "column",
    },
  };
});
const RowTwo = styled.div(({ theme, centralized }) => {
  return {
    width: "100%",
    display: "flex",
    flexDirection: "row",

    justifyContent: centralized ? "center" : "space-between",
    alignItems: "center",
    [MediaQueries.mdDown]: {
      justifyContent: "flex-start",
      overflowX: "auto",
      paddingBottom: theme.spacing(1),
    },
  };
});
const ValueCard = styled.div(({ theme }) => {
  return {
    flex: 1,
    maxWidth: Spacing(25),

    margin: "auto",
    [MediaQueries.mdDown]: {
      marginInline: Spacing(4),
    },
    [MediaQueries.smDown]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: 0,
      marginBlock: Spacing(4),
      maxWidth: Spacing(50),
      width: "100%",
    },
  };
});

const VerticalLine = styled.div(({ theme }) => {
  return {
    flex: 1,
    borderRight: "2px solid #EFEFEF",
    [MediaQueries.smDown]: {
      borderRight: "none",
      borderBottom: "2px solid #EFEFEF",
      width: "100%",
    },
  };
});

const ReportsCards = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    backgroundColor: colors.text.secondary,

    margin: "auto",
    padding: spacing(6),
    borderRadius: spacing(0.5),
    boxShadow: "5px 5px 15px #0000001A",
    minHeight: spacing(61.5),
    maxWidth: spacing(53),

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    marginInline: spacing(2),
    minWidth: spacing(34.37),
    width: spacing(45),
    [MediaQueries.mdDown]: {
      minWidth: spacing(42),
    },
    [MediaQueries.smDown]: {
      minWidth: spacing(23.75),
      minHeight: spacing(31.5),
      padding: spacing(2),
      marginInline: spacing(1),
    },
  };
});

const ReportsIcon = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    backgroundColor: colors.text.tertiary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    height: spacing(20.5),
    width: spacing(20.5),
    borderRadius: spacing(20),
    [MediaQueries.smDown]: {
      height: spacing(10.625),
      width: spacing(10.625),
      padding: spacing(2),
    },
  };
});

const SmallButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: Colors.purple,
    ...FontStyles.bold16,
    paddingInline: spacing(9),
    marginTop: spacing(5),
  };
});

const FooterPage = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    paddingBlock: spacing(6),
    paddingInline: spacing(30),
    display: "flex",
    justifyContent: "space-between",
  };
});

const ButtonIcon = styled(IconButton)(({ theme }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    borderRadius: 0,
    marginRight: spacing(2),
  };
});
const SelectItens = styled(MenuItem)(() => {
  return {
    color: "white",
    ...FontStyles.bold16,
  };
});

const DivNumeros = styled.div(({ theme }) => {
  return {
    fontFamily: fonts.bold,
    fontSize: fonts.sizes.extraLarge,
    textAlign: "center"
  };
});

const Pbox = styled.p(({ theme }) => {
  return {
    margin: 0,
    marginTop: Spacing(1),
    padding: 0,
    width: "100%",
    textAlign: "center"
  };
});

const CorpoPaper = styled.div(({ theme }) => {
  return {
    minHeight: Spacing(16),
  };
});

const CorpoTabela = styled.div(({ theme }) => {
  return {
    maxWidth: "100%",
    marginTop: Spacing(5),
  };
});


const IconeEcom = styled.img(({ theme }) => {
  // const { spacing } = theme;
  return {
    height: "auto",
    float: "left",
    width: Spacing(5.8),
    marginRight: Spacing(2) 
  };
});


const BootstrapInput = styled.input(({ theme }) => {
  return {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    } 
  };
});


const Styles = {
  SelectItens,
  ButtonIcon,
  FooterPage,
  Row,
  ValueCard,
  Container,
  Content,
  Title,
  Subtitle,
  Header,
  EcomLogo,
  P,
  Span,
  ImageBackground,
  Card,
  CardTitle,
  CardHeader,
  SelectRegion,
  SelectLabel,
  SelectIcon,
  Footer,
  Block,
  ShadowCard,
  VerticalLine,
  ReportsCards,
  SmallButton,
  ReportsIcon,
  RowTwo,
  DivNumeros,
  Pbox,
  CorpoPaper,
  CorpoTabela,
  IconeEcom,
  BootstrapInput
};

export default Styles;
