import React from "react";

import { CustomText, FontStyles } from "../../../genericComponents";
import Styles from "../styles";

import fonts from "../../../config/fonts";
import { Icons, Spacing } from "../../../config";

import { useNavigate } from "react-router-dom";
import { MenuItem, withStyles } from "@material-ui/core";
const ListItem = withStyles({
  root: {
    color: "#C2C2C2",
    ...FontStyles.bold16,
    "&$selected": {
      backgroundColor: "white",
      color: "#5C42DB",
      "& .MuiListItemIcon-root": {
        color: "#5C42DB",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#5C42DB",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#5C42DB",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  selected: {},
})(MenuItem);
function ReportBox({ item, listData }) {
  var viewport_width = document.documentElement.clientWidth;
  const fontSize = viewport_width > 600 ? [4, 3] : [2.4, 2];
  const [date, setDate] = React.useState(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (listData) {
      setDate(listData?.options[0]?.value);
    }
  }, [listData]);

  const GoTo = (event) => {
    navigate(`/report/${item?.title}/${item?.id}/${event}/${item?.subTitle}`);
  };
  return (
    <Styles.ReportsCards key={item?.id}>
      <Styles.ReportsIcon>
        <Icons.Financeiro style={{ fill: "#5C42DB" }} />
      </Styles.ReportsIcon>
      <div style={{ marginTop: Spacing(4) }}>
        <CustomText
          textColor={2}
          styles={{ textAlign: "center" }}
          fontFamily={fonts.bold}
          fontSize={fontSize[0]}>
          {item?.title}
        </CustomText>
      </div>
      {listData ? (
        <Styles.SelectRegion
          disableUnderline
          value={date}
          IconComponent={() => <Styles.SelectIcon />}
          onChange={(event) => setDate(event.target.value)}>
          {listData?.options?.map((item) => {
            return (
              <ListItem
                onClick={() => GoTo(item.value)}
                key={item.id}
                value={item.value}>
                {item.label}
              </ListItem>
            );
          })}
        </Styles.SelectRegion>
      ) : (
        <Styles.NoData>⎯</Styles.NoData>
      )}
    </Styles.ReportsCards>
  );
}

export default ReportBox;
