import React from "react";
import { Grid } from "@material-ui/core";
import ptBr from "../../../config/texts/pt-br";
import { useNavigate } from "react-router-dom";
import {
  ButtonContained,
  ButtonText,
  InputPassword,
} from "../../../genericComponents";
import Styles from "../ForgetPasswordStyles";
import { FormFull } from "form-full";

function StepPassword({ RenderSwiperDots, onSubmit, goBack, loading }) {
  const texts = ptBr.forgetPassword;
  const navigate = useNavigate();
  const [buttonDisabled, setButton] = React.useState(true);
  const [error, setError] = React.useState(false);
  const validatePassword = (data) => {
    if (data.newPassword === data.confirmPassword) {
      setError(false);
      onSubmit({ password: data.confirmPassword });
    } else {
      setError(true);
    }
  };
  return (
    <FormFull onSubmit={validatePassword}>
      <Styles.Content className="corpoesqueci forcamargem">
        <Styles.Title className="tituloesqueci">{texts.titleStepTree}</Styles.Title>
        <Styles.Subtitle className="subtituloesqueci"> {texts.subTitle[2]}</Styles.Subtitle>
        <Styles.InputBox>
          <InputPassword
            name="newPassword"
            label={texts.password}
            required={texts.passwordMessage}
            onChange={() => {
              setButton(false);
            }}
          />
          <InputPassword
            name="confirmPassword"
            label={texts.confirmPassword}
            required={texts.passwordMessage}
            onChange={() => {
              setButton(false);
            }}
          />
          {error && (
            <Styles.ErrorMessage>
              As senhas não correspondem.
            </Styles.ErrorMessage>
          )}
        </Styles.InputBox>

        <ButtonContained
          loading={loading}
          disabled={buttonDisabled}
          feedback={true}
          action="submit"
          label={texts.continue[2]}
          className="botaocriar"
        />
      </Styles.Content>

      <Styles.Footer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {RenderSwiperDots(texts.titleStepTree)}
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={7}
            style={{ display: "none", justifyContent: "space-between" }}>
            <ButtonText loading={loading} onClick={goBack} fullWidth={false}>
              <Styles.ArrowCircle>
                <Styles.ArrowLeft />
              </Styles.ArrowCircle>
              {texts.onBack}
            </ButtonText>
            <ButtonText
              action="submit"
              loading={loading}
              disabled={buttonDisabled}
              fullWidth={false}
              className="botaocriar">
              {texts.onNext}
              <Styles.ArrowCircle>
                <Styles.ArrowRight />
              </Styles.ArrowCircle>
            </ButtonText>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <ButtonText
              onClick={() => navigate("/")}
              name="home"
              loading={loading}
              fullWidth={false}
              className="botaoacoesvoltar">
              {texts.onBackMenu}
            </ButtonText>
          </Grid>
        </Grid>
      </Styles.Footer>
    </FormFull>
  );
}

export default StepPassword;
