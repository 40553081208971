import styled from "styled-components";
import React from "react";
import Grid from '@material-ui/core/Grid';

import fonts from "../../../config/fonts";
import { IconButton } from "@material-ui/core";
import CustomText from "../../customText/CustomText";
import Styles from "./FooterStyles";
import images from "../../../config/images";
import { Icons, MediaQueries } from "../../../config";


const FooterPage = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    paddingBlock: spacing(6),
    display: "flex",
    justifyContent: "space-between",
    [MediaQueries.smDown]: {
      alignItems: "center",
      flexDirection: "column",
    },
  };
});
const ButtonIcon = styled(IconButton)(({ theme }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    borderRadius: 0,
    marginRight: spacing(2),
  };
});

function Footer() {
  return (
    <FooterPage>
      <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} xl={4} md={4} justifyContent="center">
            <Styles.FooterConteudo>
              <Styles.EcomLogoFooter src={images.LogoEcomFooter} />
            </Styles.FooterConteudo>
            <Styles.CaixaRedeSocial>
              <Grid container justifyContent="center">
                <Grid item xs={4} sm={4} xl={4} md={4}>
                  <Styles.FooterConteudo>
                    <ButtonIcon>
                      <Styles.EcomLogoFooter 
                        src={images.facebook}
                        onClick={() => {
                          window.open("https://www.facebook.com/ecomenergia/");
                        } } 
                      />
                    </ButtonIcon>
                  </Styles.FooterConteudo>
                </Grid>
                <Grid item xs={4} sm={4} xl={4} md={4}>
                  <Styles.FooterConteudo>
                    <ButtonIcon>
                      <Styles.EcomLogoFooter 
                          src={images.instagram}
                          onClick={() => {
                            window.open("https://www.instagram.com/ecomenergia/");
                          } } 
                      />
                    </ButtonIcon>
                  </Styles.FooterConteudo>
                </Grid>
                <Grid item xs={4} sm={4} xl={4} md={4}>
                  <Styles.FooterConteudo>
                    <ButtonIcon>
                      <Styles.EcomLogoFooter 
                          src={images.linkedin}
                          onClick={() => {
                            window.open("https://www.linkedin.com/company/ecom-energia/posts/?feedView=all&viewAsMember=true");
                          } } 
                      />
                    </ButtonIcon>
                  </Styles.FooterConteudo>  
                </Grid>
              </Grid>
            </Styles.CaixaRedeSocial>
          </Grid>
          <Grid item xs={12} sm={12} xl={4} md={4}>
                  <Styles.pFooterBold>
                  Informações de contato
                  </Styles.pFooterBold>
                  <Styles.pFooter>
                    Rua Olimpíadas nº 205, 16° andar<br></br>
                    Vila Olímpia, São Paulo - SP<br></br>
                    CEP 04551-000
                  </Styles.pFooter>
                  <Styles.pFooter>
                    ecomenergia@ecomenergia.com.br<br></br>
                    +55 (11) 2185-9500
                  </Styles.pFooter>
          </Grid>
          <Grid item xs={12} sm={12} xl={4} md={4}>
            <Styles.pFooterBold>
              Nossos selos
            </Styles.pFooterBold>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={12} xl={4} md={4}>
                <Styles.FooterConteudo>
                  <Styles.EcomLogoFooter src={images.Selo1} />
                </Styles.FooterConteudo>
              </Grid>
              <Grid item xs={12} sm={12} xl={4} md={4}>
                <Styles.FooterConteudo>
                  <Styles.EcomLogoFooter src={images.Selo2} />
                </Styles.FooterConteudo>  
              </Grid>
              <Grid item xs={12} sm={12} xl={4} md={4}>
                <Styles.FooterConteudo>
                  <Styles.EcomLogoFooter src={images.Selo3} />
                </Styles.FooterConteudo>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
    {/* <CustomText
      fontSize={3}
      fontFamily={fonts.regular}
      styles={{ color: "#C2C2C2" }}></CustomText><div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} xl={4} md={4}>
            <Styles.EcomLogo src={images.LogoEcomFooter} />
            <ButtonIcon>
              <Icons.Linkedin
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/ecom-energia/posts/?feedView=all&viewAsMember=true"
                  );
                } } />
            </ButtonIcon>
            <ButtonIcon>
              <Icons.Facebook
                onClick={() => {
                  window.open("https://www.facebook.com/ecomenergia/");
                } } />
            </ButtonIcon>
            <ButtonIcon>
              <Icons.Insta
                onClick={() => {
                  window.open("https://www.instagram.com/ecomenergia/");
                } } />
            </ButtonIcon>
            <ButtonIcon>
              <Icons.Youtube
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/channel/UC9cs7TzwX8vq4G4NcvoOiPA"
                  );
                } } />
            </ButtonIcon>
          </Grid>
          <Grid item xs={12} sm={12} xl={4} md={4}>

          </Grid>
          <Grid item xs={12} sm={12} xl={4} md={4}>
            
          </Grid>
        </Grid>
        </>
      </div> */}
    </FooterPage>
  );
}

export default Footer;
