import styled from "styled-components";
import { Colors, Spacing } from "../../../../config";
import { FontStyles } from "../../../../genericComponents";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import fonts from "../../../../config/fonts";

const Container = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "column",
    paddingInline: Spacing(2),
    marginTop: Spacing(2),
    cursor: "pointer",
  };
});

const Content = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: 0,
    padding: 0,
  };
});

const Text = styled.p(({ title, disabled }) => {
  return {
    color: !!disabled
      ? Colors.grayDisabled
      : title
      ? Colors.purple
      : Colors.grayText,
    ...FontStyles.bold16,
    fontFamily: title ? fonts.bold : fonts.medium,
    fontSize: title ? fonts.sizes.regular : fonts.sizes.small,
    margin: Spacing(0, 6, 0, 0),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: title ? " " : 200,
  };
});

const IconArrow = styled(ArrowForwardIcon)((disabled) => {
  return {
    color: !!disabled ? Colors.grayDisabled : Colors.grayText,
    width: 20,
    marginLeft: Spacing(0.75),
  };
});

const Styles = {
  Container,
  Content,
  Text,
  IconArrow,
};

export default Styles;
