import { IconButton } from "@material-ui/core";
import styled from "styled-components";
import { Colors, Icons, Spacing } from "../../config";
import FontStyles from "../../genericComponents/styles/fontStyles";

const Container = styled.div(() => {
  return {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: `calc(100vh - ${Spacing(17)}px)`,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "white",
    overflowY: "auto",
  };
});

const ContentTitle = styled.p(() => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  };
});

const Title = styled.p(() => {
  return {
    color: Colors.purple,
    ...FontStyles.bold42,
    marginLeft: Spacing(2),
  };
});

const Dot = styled.div(() => {
  return {
    display: "flex",
    height: Spacing(20),
    width: Spacing(20),
    borderRadius: Spacing(20),
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.softGray,
    marginBottom: Spacing(4),
  };
});

const Letter = styled.p(() => {
  return {
    color: Colors.purple,
    textTransform: "uppercase",
    ...FontStyles.bold22,
    fontSize: 79,
  };
});

const SubTitle = styled.p(() => {
  return {
    color: Colors.purple,
    ...FontStyles.bold22,
  };
});

const Text = styled.p(() => {
  return {
    color: Colors.grayText,
    ...FontStyles.medium16,
  };
});

const EditIcon = styled(Icons.Edit)(() => {
  return {
    width: 18,
    height: "auto",
  };
});

const ButtonIcon = styled(IconButton)(() => {
  return {
    padding: 0,
    borderRadius: 0,
    marginRight: Spacing(2),
  };
});

const FooterPage = styled.div(() => {
  return {
    width: "100%",
    paddingBottom: Spacing(3),
    paddingInline: Spacing(20),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };
});

const Line = styled.div(() => {
  return {
    display: "block",
    border: 0,
    borderBottom: "1px solid #EFEFEF",
    paddingTop: Spacing(6),
    marginBottom: Spacing(3),
  };
});

const FooterText = styled.p(() => {
  return {
    color: Colors.grayText,
    ...FontStyles.medium22,
  };
});

const Styles = {
  Container,
  ContentTitle,
  Title,
  Dot,
  Letter,
  SubTitle,
  Text,
  EditIcon,
  ButtonIcon,
  FooterPage,
  Line,
  FooterText,
};

export default Styles;
