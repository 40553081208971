import React from "react";
import { Grid } from "@material-ui/core";
import ptBr from "../../../config/texts/pt-br";
import images from "../../../config/images";
import Styles from "./ImageLoginStyles";
import { Spacing } from "../../../config";
import { customModal } from "../../../genericComponents/modals/utils";

function ImageLogin2() {
  const texts = ptBr.banner;
  function comingSoon() {
    
  }
  return (
    <Grid
      container
      xs={12}
      style={{
        height: "100vh",
      }}>
      <Styles.ImageBG $Image={`url(${images.falandocelular})`} className="bgfalando">
        
        
      </Styles.ImageBG>
    </Grid>
  );
}

export default ImageLogin2;
