import { makeStyles } from "@material-ui/core";
import React from "react";
import Styles from "./ItemsStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 430,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: 5,
  },
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function Items({subTitle, text, edit, onClick}) {
  const classes = useStyles();
  return (
  <Styles.Container>
    <Styles.SubTitle>{subTitle}</Styles.SubTitle>
    <Styles.TextContent>
      <Styles.Text>{text}</Styles.Text>
      {edit ? 
      <Styles.ButtonIcon className={classes.button} onClick={onClick}>
        <Styles.EditIcon />
      </Styles.ButtonIcon> 
      : null}
    </Styles.TextContent>
  </Styles.Container>
  );
}

export default Items;
