import React from "react";
import styled from "styled-components";
import { MediaQueries } from "../../config";

import FontStyle from "../styles/fontStyles";

const Text = styled.p(
  ({ $fontFamily, $fontSize, $textColor, $styles, theme }) => {
    const { palette: colors, spacing } = theme;

    return {
      padding: 0,
      paddingBottom: spacing(0.75),
      margin: 0,
      fontFamily: $fontFamily ? $fontFamily : FontStyle.medium12.fontFamily,
      fontSize: $fontSize ? spacing($fontSize) : FontStyle.medium12.fontSize,
      color: $textColor
        ? $textColor === 2
          ? colors.text.tertiary
          : colors.text.secondary
        : colors.text.primary,
      [MediaQueries.smDown]: {
        fontSize: $fontSize ? spacing($fontSize) : FontStyle.medium12.fontSize,
      },
      ...$styles,
    };
  }
);

function CustomText({ children, styles, fontFamily, fontSize, textColor }) {
  return (
    <Text
      $styles={styles}
      $fontFamily={fontFamily}
      $fontSize={fontSize}
      $textColor={textColor}>
      {children}
    </Text>
  );
}

export default CustomText;
