import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import Styles from "./HeaderStyles";
import { Colors, MediaQueries, Spacing } from "../../config";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, AppBar, Toolbar, Grid } from "@material-ui/core";
import UserInfo from "./UserInfo/userInfo";
import Menu from "../menu/menu";
import images from "../../config/images";
import { SessionStorage } from "../../utils";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { paths } from "../../navigation/navigate";
import Hidden from '@material-ui/core/Hidden';


import './header.css';
import './header2.css';

const drawerWidth = 480;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: theme.spacing(17),
  },
  appBar: {
    height: theme.spacing(12),
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: Colors.azulEcom,
    boxShadow: "none",
    // padding: Spacing(2, 20, 0),
    //960
    [MediaQueries.mdDown]: {
      padding: Spacing(2, 1, 0),
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    padding: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  customHoverFocus: {
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  },
  Tabs:{
    color: Colors.branco
  }
}));


  

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const [open] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    console.log(newValue);
    if(newValue == 0) {
      navigate(paths.home);
    }
    
    if(newValue == 1) {
      navigate(paths.cadastro);
    }
    
    if(newValue == 2) {
      window.open(
        "https://ecomenergia.app4shark.com/"
      );
    }

    if(newValue == 3) {
      navigate(paths.conteudo);
    }

    setValue(newValue);
  };


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid container justifyContent="center" spacing={12}>
          <Grid item xs={12} sm={12} xl={4} md={4}>

            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}>
              <Toolbar>
              <Menu className="menu-header" />
                <Grid
                  container
                  xs={3}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center">
                  {/* <Hidden only={['lg','md', 'xl']} > */}
                    
                  {/* </Hidden> */}
                  <Hidden smDown implementation="css">
                    <Styles.EcomLogo src={images.LogoEcom} />
                  </Hidden>
                </Grid>

                <Grid
                  container
                  xs={9}
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  <Hidden smUp implementation="css">
                    <Styles.EcomLogo src={images.LogoEcom} />
                  </Hidden>
                  <Hidden only={['sm', 'xs']}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      className={classes.Tabs}
                      centered
                    >
                      <Tab label="Dashboard" />
                      <Tab label="Dados Parceiro" />
                      <Tab label="Comissionamento" />
                      <Tab label="Conteudo" />
                    </Tabs>
                    </Hidden>
                </Grid>
                
                
              </Toolbar>
              
            </AppBar>
          </Grid>
      </Grid>
    </div>
  );
}
