import axios from "axios";
import { errorModal } from "../../genericComponents/modals/utils";
import { create } from "apisauce";
import { ToastContainer, toast } from 'react-toastify';

async function caller(methodFunc, endpoint, model, body, axiosConfig) {
  const response = await methodFunc(endpoint, body, axiosConfig);
  
  if (response.ok) {
    console.log(response);
    if (model) {
      return model(response);
    } else {
      return response;
    }
  } else {
    const apiMiddleware = process.env.REACT_APP_API_MIDDLEWARE;
    var baseURL = apiMiddleware + 'api/log/front';
    const errorMsg= response?.data?.message ? response?.data?.message :  response?.data + ": error from api"
    console.log(response?.data);
    var dataLog = {"descricao":endpoint,"valores":errorMsg};

    axios
    .post(baseURL, dataLog)
    .then((response) => {
      console.log(response);
    });
    
    toast.error('Ops... Ocorreu um erro, contate seu representante!', {
        position: toast.POSITION.TOP_CENTER
    });

    // errorModal.setInfos(
    //   "Ops...",
    //   "Ocorreu um erro, contate seu representante!",
    //   { label: "OK", onClick: () => errorModal.close() },
    //   null,
    //   null,
    //   false
    // );

    return Object.assign(new Error("error"), {
      message: "error from api",
      ...response,
      errorType: endpoint,
    });
    
  }
}

export default caller;
