import styled from "styled-components";
import { Colors, Spacing } from "../../../config";
import { ButtonContained, ButtonText } from "../../../genericComponents";
import FontStyles from "../../../genericComponents/styles/fontStyles";

const Container = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    paddingInline: Spacing(4),
  };
});

const ErrorMessageContainer = styled.div(() => {
  return {
    paddingInline: Spacing(4),
    justifyContent: "flex-start",
    margin: Spacing(1),
  };
});

const ConfirmButton = styled(ButtonContained)(({ theme }) => {
  return {
    backgroundColor: Colors.orange,
    color: Colors.white,
    ...FontStyles.bold16,
    width: 400,
    "&:hover": {
      backgroundColor: Colors.orange,
    }
  };
});

const ExitButton = styled(ButtonText)(({ theme }) => {
  return {
    color: Colors.orange,
    ...FontStyles.bold16,
    borderBottom: "2px solid #DEA017",
    borderRadius: 0,
    padding: Spacing(2, 0, 0, 0),
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };
});

const PaddingModal = styled.div(({ theme }) => {
  return {
    paddingInline: Spacing(10),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
});

const Styles = {
  Container,
  ConfirmButton,
  ExitButton,
  PaddingModal,
  ErrorMessageContainer,
};

export default Styles;
