import React from "react";

import { CustomText, FontStyles } from "../../../genericComponents";
import Styles from "../HomeStyles";

import fonts from "../../../config/fonts";
import { Icons } from "../../../config";
import { useNavigate } from "react-router-dom";
import { MenuItem, withStyles } from "@material-ui/core";
const ListItem = withStyles({
  root: {
    color: "#C2C2C2",
    ...FontStyles.bold16,
    "&$selected": {
      backgroundColor: "white",
      color: "#5C42DB",
      "& .MuiListItemIcon-root": {
        color: "#5C42DB",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#5C42DB",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#5C42DB",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  selected: {},
})(MenuItem);
function ReportBox({ item }) {
  const [date, setDate] = React.useState(item?.options[0]?.value);
  const navigate = useNavigate();
  const GoTo = (event) => {
    navigate(
      `/report/${item?.title}/${item?.reportTypeId}/${event.id}/${item?.subTitle}`
    );
  };
  var viewport_width = document.documentElement.clientWidth;
  const fontSize = viewport_width > 600 ? [4, 3] : [2.25, 2];

  return (
    <Styles.ReportsCards key={item?.reportTypeId}>
      <Styles.ReportsIcon>
        <Icons.Financeiro
          style={{
            fill: "#5C42DB",
          }}
        />
      </Styles.ReportsIcon>
      <div>
        <CustomText
          textColor={2}
          styles={{ textAlign: "center" }}
          fontFamily={fonts.bold}
          fontSize={fontSize[0]}>
          {item?.title}
        </CustomText>
      </div>
      <Styles.SelectRegion
        disableUnderline
        value={date}
        IconComponent={() => <Styles.SelectIcon />}
        onChange={(event) => setDate(event.target.value)}>
        {item?.options?.map((data) => {
          return (
            <ListItem
              onClick={() => GoTo(data)}
              key={data.id}
              value={data.value}>
              {data.label}
            </ListItem>
          );
        })}
      </Styles.SelectRegion>
    </Styles.ReportsCards>
  );
}

export default ReportBox;
