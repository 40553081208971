import { Backdrop, Modal, Paper } from "@material-ui/core";
import styled from "styled-components";
import { Colors, MediaQueries, Spacing } from "../../../config";
import CloseIcon from "@material-ui/icons/Close";
import FontStyles from "../../styles/fontStyles";
import { ButtonContained, ButtonText } from "../..";

const StyledBackdrop = styled(Backdrop)({
  backdropFilter: "blur(5px)",
  WebkitBackdropFilter: "blur(5px)",
  backgroundColor: "#00000020",
});

const StyledModal = styled(Modal)({
  outlineWidth: 0,
  borderWidth: 0,
  ":focus": {},
});

const Container = styled(Paper)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    position: "absolute",
    width: 400,
    maxWidth: 470,
    maxHeight: "75vh",
    paddingBlock: Spacing(3),
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    outline: "none",
    backgroundColor: colors.whiteBackground,
    overflowY: "auto",
    [MediaQueries.smUp]: {
      width: `calc(100% - ${spacing(2)}px)`,
    },
  };
});

const ContentContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    overflowY: "auto",
    maxHeight: `calc(95vh - ${spacing(2)}px)`,
  };
});

const HeaderContainer = styled.div(({ theme, noExit }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingInline: spacing(3.5),
    // marginTop: noExit ? spacing(1.5) : spacing(0.5),
  };
});

const Close = styled(CloseIcon)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: colors.carbon,
    width: spacing(3),
    height: spacing(3),
  };
});

const TextContainer = styled.div(({ theme }) => {
  return {
    color: "black",
    paddingBottom: Spacing(1),
    paddingInline: Spacing(5.5),
    textAlign: "center",
    [`@media only screen and (max-height: 700px)`]: {
      padding: 0,
      margin: 0,
    },
  };
});
const PaddingModal = styled.div(({ theme }) => {
  return {
    paddingInline: Spacing(3.5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
});
const ContentText = styled.p(({ theme }) => {
  return {
    ...FontStyles.medium16,
    color: Colors.black,
  };
});
const Title = styled.p(({ theme }) => {
  return {
    ...FontStyles.bold22,
    color: Colors.purple,
    [`@media only screen and (max-height: 700px)`]: {
      padding: 0,
      margin: 0,
      fontSize: theme.spacing(2.5),
    },
  };
});
const FooterContainer = styled.div(({ theme }) => {
  return {
    marginTop: Spacing(1),
    paddingInline: Spacing(3.5),
    display: "flex",
    justifyContent: "center",
  };
});

const ConfirmButton = styled(ButtonContained)(({ theme }) => {
  return {
    backgroundColor: Colors.purple,
    color: Colors.white,
    ...FontStyles.bold16,
    width: 400,
    "&:hover": {
      backgroundColor: Colors.purple,
    },
  };
});

const ExitButton = styled(ButtonText)(({ theme }) => {
  return {
    color: Colors.purple,
    ...FontStyles.bold16,
    borderBottom: "2px solid #5C42DB",
    borderRadius: 0,
    padding: Spacing(2, 0, 0, 0),
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
    },
  };
});

const Styles = {
  StyledBackdrop,
  StyledModal,
  Container,
  ContentContainer,
  HeaderContainer,
  Close,
  TextContainer,
  PaddingModal,
  ContentText,
  Title,
  FooterContainer,
  ConfirmButton,
  ExitButton,
};

export default Styles;
