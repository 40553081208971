import styled from "styled-components";
import colors from "../../config/colors";

import { MediaQueries, Spacing } from "../../config";
import { FontStyles } from "../../genericComponents";
import { MenuItem, Select } from "@material-ui/core";
import { ReactComponent as Pin } from "../../assets/svg/icone-flecha-select.svg";

const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    width: "100vw",
    height: `calc(100vh - ${spacing(17)}px)`,
    flexDirection: "column",

    justifyContent: "flex-start",
    alignItems: "center",
    overflowY: "auto",
    overflowX: "hidden",
  };
});

const Content = styled.div(({ theme, alternative, marginTop }) => {
  const { palette: colors, spacing } = theme;
  return {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: `100%`,
    backgroundColor: alternative ? colors.background.secondary : "transparent",
    paddingInline: "13%",
    paddingBlock: spacing(8, 15.5),
    paddingTop: marginTop && spacing(6),
    [MediaQueries.mdDown]: {
      paddingInline: spacing(4),
    },
  };
});

const Header = styled.div(({ theme }) => {
  // const { spacing } = theme;
  return {
    width: "32rem",
    height: "fit-content",
    marginBottom: Spacing(3),
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
  };
});

const Title = styled.p(({ theme }) => {
  // const { palette: colors } = theme;
  return {
    color: colors.purple,
    opacity: 0.88,
    margin: 0,
    textAlign: "left",
    ...FontStyles.bold42,
    paddingTop: Spacing(8),
  };
});

const Subtitle = styled.p(({ theme }) => {
  // const { palette: colors } = theme;
  return {
    color: colors.grayText,
    ...FontStyles.medium16,
    opacity: 0.88,
    margin: 0,
    paddingTop: Spacing(2.5),
    paddingBottom: Spacing(3),
  };
});

//----

const Card = styled.div(({ $Image }) => {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    border: "1px solid #EFEFEF",
    borderRadius: Spacing(0.6),
  };
});
const CardHeader = styled.div(({ theme, noLine }) => {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: !noLine && Spacing(4),
    borderBottom: !noLine && "1px solid #EFEFEF",
  };
});
const CardTitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    margin: 0,
    padding: 0,
    color: colors.text.secondary,
    ...FontStyles.bold22,
  };
});

const SelectLabel = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    margin: 0,
    padding: 0,
    marginRight: Spacing(1),
    color: colors.text.tertiary,
    ...FontStyles.bold22,
  };
});
const SelectRegion = styled(Select)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    margin: 0,
    padding: 0,
    color: colors.text.tertiary,
    ...FontStyles.bold22,
    marginTop: spacing(4),
    "& .MuiSelect-select": {
      padding: 0,
    },
    [MediaQueries.smDown]: {
      marginTop: 0,
      ...FontStyles.bold18,
    },
  };
});
const NoData = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    margin: 0,
    padding: 0,
    color: colors.text.tertiary,
    ...FontStyles.bold22,
    marginTop: spacing(6),
  };
});
const SelectItens = styled(MenuItem)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.tertiary,
    ...FontStyles.bold16,
  };
});
const SelectIcon = styled(Pin)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.tertiary,
    width: Spacing(2),
    height: Spacing(2),
    fill: colors.text.tertiary,
    "& .path": {
      fill: colors.text.tertiary,
    },
  };
});

const ShadowCard = styled.div(({ theme }) => {
  return {
    marginTop: Spacing(6),

    width: "100%",
    height: "100%",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: `0px ${Spacing(10.5)}px ${Spacing(7.5)}px #2C4D741A`,
    borderRadius: Spacing(0.6),
    padding: Spacing(5.5),
  };
});

const ReportsCards = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    backgroundColor: colors.text.secondary,

    margin: "auto",
    padding: spacing(6),
    borderRadius: spacing(0.5),
    boxShadow: "5px 5px 15px #0000001A",
    minHeight: spacing(61.25),
    height: "100%",
    maxWidth: spacing(53),
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    marginInline: spacing(2),
    minWidth: spacing(34.37),
    [MediaQueries.mdDown]: {
      minWidth: spacing(42),
    },
    [MediaQueries.smDown]: {
      minWidth: spacing(23.75),
      minHeight: spacing(31.5),
      padding: spacing(2),
      marginInline: spacing(1),
    },
    [MediaQueries.ssmDown]: {
      maxWidth: spacing(22),
    },
  };
});

const ReportsIcon = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    backgroundColor: colors.text.tertiary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    height: spacing(20.5),
    width: spacing(20.5),
    borderRadius: spacing(20),
    [MediaQueries.smDown]: {
      height: spacing(10.625),
      width: spacing(10.625),
      padding: spacing(2),
    },
  };
});

const Styles = {
  Container,
  Content,
  Title,
  Subtitle,
  Header,

  Card,
  CardTitle,
  CardHeader,
  SelectRegion,
  SelectItens,
  SelectLabel,
  SelectIcon,

  ShadowCard,

  ReportsCards,

  ReportsIcon,
  NoData,
};

export default Styles;
