import React from "react";
import { Grid } from "@material-ui/core";
import ptBr from "../../../config/texts/pt-br";

import { useNavigate } from "react-router-dom";
import {
  ButtonContained,
  ButtonText,
  InputEmail,
} from "../../../genericComponents";
import Styles from "../ForgetPasswordStyles";
import { FormFull } from "form-full";
import { validations } from "../../../utils";
function StepEmail({ RenderSwiperDots, onSubmit, loading }) {
  const texts = ptBr.forgetPassword;
  const [buttonDisabled, setButton] = React.useState(true);
  const navigate = useNavigate();
  return (
    <FormFull onSubmit={onSubmit}>
      <Styles.Content  className="corpoesqueci">
        <Styles.Title className="tituloesqueci">{texts.titleStepOne}</Styles.Title>
        <Styles.Subtitle className="subtituloesqueci"> {texts.subTitle[0]}</Styles.Subtitle>
        <Styles.InputBox className="boxnovo">
          <InputEmail
            className="inputsnovos"
            name="email"
            label={texts.user}
            required={texts.userMessage}
            validation={validations.isEmailValid}
            onChange={() => {
              setButton(false);
            }
          }
          />
        </Styles.InputBox>

        <ButtonContained
          name="continue"
          loading={loading}
          disabled={buttonDisabled}
          feedback={true}
          action="submit"
          label={texts.continue[0]}
          className="botaocriar"
        />
      </Styles.Content>

      <Styles.Footer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {RenderSwiperDots(texts.titleStepOne)}
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={7}
            style={{ display: "none", justifyContent: "space-between" }}>
            <ButtonText
              name="back"
              loading={loading}
              fullWidth={false}
              className="botaonovo"
              onClick={() => navigate("/")}>
              <Styles.ArrowCircle>
                <Styles.ArrowLeft />
              </Styles.ArrowCircle>
              {texts.onBack}
            </ButtonText>
            <ButtonText
              name="next"
              action="submit"
              className="botaonovo"
              loading={loading}
              disabled={buttonDisabled}
              fullWidth={false}>
              {texts.onNext}
              <Styles.ArrowCircle>
                <Styles.ArrowRight />
              </Styles.ArrowCircle>
            </ButtonText>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <ButtonText
              onClick={() => navigate("/")}
              name="home"
              loading={loading}
              fullWidth={false}
              className="botaonovovoltar">
              {texts.onBackMenu}
            </ButtonText>
          </Grid>
        </Grid>
      </Styles.Footer>
    </FormFull>
  );
}

export default StepEmail;
