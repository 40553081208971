import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import images from "../../config/images";
import { Swiper } from "../../genericComponents";
import Styles from "./ForgetPasswordStyles";
import StepEmail from "./Steps/StepEmail";
import StepCode from "./Steps/StepCode";
import StepPassword from "./Steps/StepPassword";
import Completed from "./Steps/Completed";
import { api } from "../../services/index";
import { hooks } from "../../utils";
import ImageLogin from "../login/image/imageLogin";
function ForgetPassword() {
  const { loading, call } = hooks.useRequest();
  const [formData, setFormData] = useState({});

  const FinishStepOne = async (data, Callback) => {
    call(null, api.sendRecoveryPasswordRequest(data), (response) => {
      if (response.ok) {
        setFormData({ ...formData, ...data });
        Callback();
      } else {
      }
    });
  };

  const FinishStepTwo = async (data, Callback) => {
    call(
      null,
      api.recoveryPasswordValidate({ ...formData, ...data }),
      (response) => {
        if (response.ok) {
          setFormData({ ...formData, ...data, token: response?.data?.token });
          Callback();
        } else {
        }
      }
    );
  };

  const FinishStepThree = async (data, Callback) => {
    const requestBody = {
      email: formData.email,
      token: formData.token,
      ...data,
    };

    call(null, api.sendRecoveryPasswordNewPassword(requestBody), (response) => {
      if (response.ok) {
        setFormData({ ...formData, ...data, token: response?.data?.token });
        Callback();
      } else {
      }
    });
  };
  return (
    <div className="bgfundo">
      <Grid
        container
        spacing={0}
        direction={"row"}
        wrap={"nowrap"}
        alignItems={"flex-start"}>
        <Grid item xs={12} sm={6}>
          <Styles.Container>
            <Styles.Header className="cabecalogo">
              <Styles.EcomLogo src={images.logonovo} className="logoesqueci" />
            </Styles.Header>

            <Swiper
              screens={[
                ({ goNext, RenderSwiperDots, title = "outro" }) => (
                  <StepEmail
                    loading={loading}
                    onSubmit={(data) => {
                      FinishStepOne(data, goNext);
                    }}
                    goBack={() => {}}
                    RenderSwiperDots={RenderSwiperDots}
                  />
                ),
                ({ goNext, RenderSwiperDots, goPrevious }) => (
                  <StepCode
                    loadingOut={loading}
                    onSubmit={(data) => {
                      FinishStepTwo(data, goNext);
                    }}
                    sendCode={() => api.sendRecoveryPasswordRequest(formData)}
                    goBack={() => goPrevious()}
                    RenderSwiperDots={RenderSwiperDots}
                  />
                ),
                ({ goNext, RenderSwiperDots, goPrevious }) => (
                  <StepPassword
                    loading={loading}
                    onSubmit={(data) => {
                      FinishStepThree(data, goNext);
                    }}
                    goBack={() => goPrevious()}
                    RenderSwiperDots={RenderSwiperDots}
                  />
                ),
                ({ goNext, RenderSwiperDots, goPrevious }) => (
                  <Completed
                    loading={loading}
                    goBack={() => goPrevious()}
                    RenderSwiperDots={RenderSwiperDots}
                  />
                ),
              ]}
            />
          </Styles.Container>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ImageLogin />
        </Grid>
      </Grid>
    </div>
  );
}

export default ForgetPassword;
