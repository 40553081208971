import React from "react";
import Styles from "./styles";
import { api } from "../../services";
import { CustomText, Footer } from "../../genericComponents";
import images from "../../config/images";
import { hooks, SessionStorage } from "../../utils";
import Paper from '@material-ui/core/Paper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './conteudo.css';
import { CircularProgress, Grid } from "@material-ui/core";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const apiMiddleware = process.env.REACT_APP_API_MIDDLEWARE;

export default function Conteudo() {
  const userEmail = SessionStorage.getItem("email");
  // const { setPathPageView, setIdentity } = useTrackingCode();
  // setPathPageView("/conteudo");
  // setIdentity(userEmail);
  
  
  const gerarPdfMaterialApoioFlyer = () => {
    const user_id = SessionStorage.getItem("user_id");
    const documento = 'flyer';
    window.open(apiMiddleware + 'api/gerarPdfMaterialApoio/'+user_id+'/'+documento, '_blank', 'noopener,noreferrer');
  }

  const gerarPdfMaterialApoioFlyerGeral = () => {
    const user_id = SessionStorage.getItem("user_id");
    const documento = 'geral';
    window.open(apiMiddleware + 'api/gerarPdfMaterialApoio/'+user_id+'/'+documento, '_blank', 'noopener,noreferrer');
  }

  return (
    <Styles.Container>
      <Styles.Content>
         <h1>
            Acesse o Conteúdo de Apoio
         </h1>
          <p>
            Acesse abaixo o materiais que a Ecom preparou para te apoiar no dia a dia
          </p>
          <div className="titulo_apoio"><Styles.IconeEcom src={images.iconeEcom} />Materiais de Divulgação</div>
          <br />
          <Grid container justifyContent="left" spacing={4}>
            <Grid item xs={12} sm={12} xl={2} md={2}>
              <Paper 
                variant="outlined"
                className="papelfinger"
                onClick={gerarPdfMaterialApoioFlyer}
              >
                <Styles.CorpoPaper>
                  <Styles.DivNumeros>
                    FLYER<br />1
                  </Styles.DivNumeros>
                  <Styles.Pbox>
                    Apresentação<br />Institucional
                  </Styles.Pbox>
                </Styles.CorpoPaper>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} xl={2} md={2}>
              <Paper 
                variant="outlined"
                className="papelfinger"
                onClick={gerarPdfMaterialApoioFlyerGeral}
              >
                <Styles.CorpoPaper>
                  <Styles.DivNumeros>
                    FLYER<br />2
                  </Styles.DivNumeros>
                  <Styles.Pbox>
                    Apresentação<br />Institucional
                  </Styles.Pbox>
                </Styles.CorpoPaper>
              </Paper>  
            </Grid>
          </Grid>
        <ToastContainer />
      </Styles.Content>
      <Footer />
    </Styles.Container>
  );
}